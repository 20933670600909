import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {AiFillCopyrightCircle} from "react-icons/ai"
const RenderSmallDelivered = ({ orderItem }) => {

    const AllProducts = useSelector(state => state.product);
    const itemDetails = AllProducts[orderItem.product_id];
    const description = () => {
        if (orderItem.add_ons) {
            return Object.keys(orderItem.add_ons).map((key, i) => {
                if (Array.isArray(orderItem.add_ons[key])) {
                    var output = ''
                    for (var j = 0; j < orderItem.add_ons[key].length; j++) {
                        output = output + orderItem.add_ons[key][j]['value'] + ' ,'
                    }
                    // console.log('output', output)
                    return <span key={i}>{key}:{output.substring(0, output.length - 2)} ,</span>
                } else {
                    return <span key={i}>{key}:{orderItem.add_ons[key]} ,</span>
                }

            }


            )
        }
    }
    // MOdal popup vars
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // MOdal popup vars end   
    const newId = uuid().slice(0, 8);
    const displ=()=>{
        if (itemDetails) {
            return (
                <div key={newId} style={{position:"relative"}}>
                    <div className='d-flex mt-3' onClick={handleShow}>
                        <div className='w-25 d-flex justify-content-start align-items-center'><img className="DoneImage" style={{ borderRadius: "10px" }} src={itemDetails.product_list.card_img} alt={newId + "IMAGE"}></img></div>
                        <div className='w-75 ready-small-content'>
                            <div className="order-card-title cursive pe-0">{orderItem.product_name}  {`(${orderItem.order_sku})`}</div>
                            <div className="order-card-text cursive pe-0">{description()}</div>
                            <div className="btn btn-warning btn-sm quan mt-1" >{orderItem.quantity}</div>
                            {orderItem.comment?<AiFillCopyrightCircle className='commentSymbol' style={{color:"#eec510"}} />:<></>}
                        </div>
                    </div>
                    <>
                    <Modal className='remove-border' show={show} onHide={handleClose}  centered>
                    <Modal.Header className=" bg-secondary text-accent remove-border" closeButton>
                        <Modal.Title >Comments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=" bg-accent text-secondary remove-border">{orderItem.comment ? <span>{orderItem.comment}</span> : <span>No comments</span>}</Modal.Body>

                </Modal>
                    </></div>
            );
        }
    }
    
    return(<React.Fragment>{displ()}</React.Fragment>);
};

export default RenderSmallDelivered;