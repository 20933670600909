import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Footer = () => {
    const kitchenOrder = useSelector(state => state.kitchen);

    const [total, setTotal] = useState(0);

    useEffect(()=>{
        if(kitchenOrder?.data?.status === 'success'){
            setTotal(kitchenOrder.data.total_orders);
        }
        else if(kitchenOrder?.data?.status === 'failure' && kitchenOrder?.data?.data && isNaN(kitchenOrder?.data?.total_orders)){
            let j = 0;
            for(let i = 0; i < kitchenOrder?.data?.data.length; i++){
                if(kitchenOrder?.data?.data[i].status === "Preparing"){
                    j++;
                }
            }
            setTotal(j++);
        }
        else{
            setTotal(0)
        }
    },[kitchenOrder])

    return (
        <div className='container-fluid  mt-4 foot' style={{ backgroundColor: "#d1cbb9" }}>
            <div className='row' style={{ height: "90px" }}>
                <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
                    <div className='btn btn-secondary '>Total Orders : {!total ? 0 : total}</div>

                </div>
            </div>
        </div>
    );
};

export default Footer;