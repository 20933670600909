import axios from "axios"
import { toast } from "react-toastify"



export const getCompletedOrders = (data) => {
    console.log('Dispatching getCompletedOrders....', data)

    return (dispatch, getState) => {
        dispatch({
            type: "GET_SERVER_COMPLETE",
            server: {data: data}
        })

               
    }
}
export const getDeliveredOrders = (data) => {
    console.log('Dispatching getDeliveredOrders....', data)

    return (dispatch, getState) => {
        dispatch({
            type: "GET_SERVER_DELIVERED",
            server: {data: data}
        })

               
    }
}

export const getOrderOnCheckout = (order_id, branchId, token, completed) => {
    console.log("dispatching getOrderOnCheckout...", order_id);
    return (dispatch, getState) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/server/get_order_by_order_id/${order_id}?branch=${branchId}`, {
            headers: { token: token, role: "server" }
        })
            .then(server => {
                
                console.log('getOrderOnCheckout', server)
                console.log('getOrderOnCheckout', completed)
                var serverNewOrder = {...completed}
                if (server.data.msg === "Token error" || server.data.status_code === 450) {
                    dispatch({
                        type: "GET_EMPLOYEE",
                        employeeInfo: [],
                        status: "failure",
                        role: "server"
                    })
                }
                else {
                    serverNewOrder.data.data.push(server.data.data[0])
                    serverNewOrder.data.total_orders = serverNewOrder.data.total_orders + 1;
                    console.log('serverOrderRevised', serverNewOrder)
                    dispatch({
                        type: "GET_CHECKOUT_ORDER",
                        server : serverNewOrder
                    })
                  
                }

            }).catch(err => {
                dispatch({
                    type: "GET_EMPLOYEE",
                    employeeInfo: [],
                    status: "failure",
                    role: "server"
                })
            })
    }
}

export const UpdateOrderItems = (order_id, data, completed, status) => {
    console.log('Dispatching UpdateOrderItems....', (order_id, data, completed))
    return (dispatch, getState) => {
        var serverNewOrder = {...completed}
        console.log('serverNewOrder in UpdateOrderItems', serverNewOrder);
        console.log('data in UpdateOrderItems', (order_id, data, completed, status));
        for (var i = 0; i< serverNewOrder.data.data.length; i++){
            if(serverNewOrder.data.data[i].order_id == order_id){
                // console.log('inside order id', order_id)
                if(status == 201){
                    serverNewOrder.data.data[i].delivery_status = true;
                    serverNewOrder.data.total_orders = serverNewOrder.data.total_orders - 1;
                    serverNewOrder.data.data.splice(i,1);
                    // for(var j=0; j<serverNewOrder.data.data[i].kitchen.length; j++){
                    //     // console.log('inside for loop', i, j)
                        
                    //     if(serverNewOrder.data.data[i].kitchen[j].order_items_id == data.order_items_id){
                    //         // console.log('I am gere', data.quantity_completed)
                    //         serverNewOrder.data.data[i].kitchen[j].delivery_status = data.delivery_status;
                    //     }
                    // }
                    // for(var j=0; j<serverNewOrder.data.data[i].barista.length; j++){
                    //     // console.log('inside for loop', i, j)
                        
                    //     if(serverNewOrder.data.data[i].barista[j].order_items_id == data.order_items_id){
                    //         // console.log('I am gere', data.quantity_completed)
                    //         serverNewOrder.data.data[i].barista[j].delivery_status = data.delivery_status;
                    //     }
                    // }
                // }else if(status == 202){
                //     serverNewOrder.data.data.splice(i,1);
                //     serverNewOrder.data.total_orders = serverNewOrder.data.total_orders - 1
                }else{
                    for(var j=0; j<serverNewOrder.data.data[i].kitchen.length; j++){
                        // console.log('inside for loop', i, j)
                        
                        if(serverNewOrder.data.data[i].kitchen[j].order_items_id == data.order_items_id){
                            // console.log('I am gere', data.quantity_completed)
                            serverNewOrder.data.data[i].kitchen[j].delivery_status = data.delivery_status;
                        }
                    }
                    for(var j=0; j<serverNewOrder.data.data[i].barista.length; j++){
                        // console.log('inside for loop', i, j)
                        
                        if(serverNewOrder.data.data[i].barista[j].order_items_id == data.order_items_id){
                            // console.log('I am gere', data.quantity_completed)
                            serverNewOrder.data.data[i].barista[j].delivery_status = data.delivery_status;
                        }
                    }
                }
                
            }
        }

        console.log('UpdateOrderItems response...', serverNewOrder)

        dispatch({
            type: "UPDATE_ORDER_ITEMS",
            server: {...serverNewOrder}
        })

    }
}

export const CompletedOrderUpdate = (order_id, data, completed, status) => {
    console.log('Dispatching CompletedOrderUpdate....', (order_id, data, completed))
    return (dispatch, getState) => {
        var serverNewOrder = {...completed}
        console.log('serverNewOrder in CompletedOrderUpdate', serverNewOrder);
        console.log('data in CompletedOrderUpdate', (order_id, data, completed, status));
        for (var i = 0; i< serverNewOrder.data.data.length; i++){
            if(serverNewOrder.data.data[i].order_id == order_id){
                // console.log('inside order id', order_id)
                if(status == 201){
                    serverNewOrder.data.data[i].status = data.status;
                    // serverNewOrder.data.total_orders = serverNewOrder.data.total_orders - 1;
                    // serverNewOrder.data.data.splice(i,1);
                    // for(var j=0; j<serverNewOrder.data.data[i].kitchen.length; j++){
                    //     // console.log('inside for loop', i, j)
                        
                    //     if(serverNewOrder.data.data[i].kitchen[j].order_items_id == data.order_items_id){
                    //         // console.log('I am gere', data.quantity_completed)
                    //         serverNewOrder.data.data[i].kitchen[j].delivery_status = data.delivery_status;
                    //     }
                    // }
                    // for(var j=0; j<serverNewOrder.data.data[i].barista.length; j++){
                    //     // console.log('inside for loop', i, j)
                        
                    //     if(serverNewOrder.data.data[i].barista[j].order_items_id == data.order_items_id){
                    //         // console.log('I am gere', data.quantity_completed)
                    //         serverNewOrder.data.data[i].barista[j].delivery_status = data.delivery_status;
                    //     }
                    // }
                // }else if(status == 202){
                //     serverNewOrder.data.data.splice(i,1);
                //     serverNewOrder.data.total_orders = serverNewOrder.data.total_orders - 1
                }
                for(var j=0; j<serverNewOrder.data.data[i].kitchen.length; j++){
                    // console.log('inside for loop', i, j)
                    
                    if(serverNewOrder.data.data[i].kitchen[j].order_items_id == data.order_items_id){
                        // console.log('I am gere', data.quantity_completed)
                        serverNewOrder.data.data[i].kitchen[j].status = data.status;
                    }
                }
                for(var j=0; j<serverNewOrder.data.data[i].barista.length; j++){
                    // console.log('inside for loop', i, j)
                    
                    if(serverNewOrder.data.data[i].barista[j].order_items_id == data.order_items_id){
                        // console.log('I am gere', data.quantity_completed)
                        serverNewOrder.data.data[i].barista[j].status = data.status;
                    }
                }
                
                
            }
        }

        console.log('CompletedOrderUpdate response...', serverNewOrder)

        dispatch({
            type: "UPDATE_ORDER_ITEMS",
            server: {...serverNewOrder}
        })

    }
}


export const UpdateAllOrderItems = (data, completed) => {
    console.log('Dispatching UpdateAllOrderItems....', data)
    return (dispatch, getState) => {
        var serverNewOrder = {...completed}
        console.log('serverNewOrder in UpdateAllOrderItems', serverNewOrder);

        for (var i = 0; i< serverNewOrder.data.data.length; i++){
            if(serverNewOrder.data.data[i].order_id == data.order_id){
                console.log('inside order id', data.order_id)
                
                serverNewOrder.data.data.splice(i,1);
                serverNewOrder.data.total_orders = serverNewOrder.data.total_orders - 1
                
                
            }
        }

        console.log('UpdateAllOrderItems response...', serverNewOrder)

        dispatch({
            type: "UPDATE_ALL_ORDER_ITEMS",
            server: {...serverNewOrder}
        })

    }
}

export const getServerDelivered = (page,branch_id,token,role) => {
    return (dispatch, getState) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/server/get_delivered_orders/${page}?branch=${branch_id}`,{
            headers:{token:token,role:"server"}
        })
            .then(server => {
                if (server.data.msg==="Token error"  ||  server.data.status_code===450) {
                    dispatch({
                        type: "GET_EMPLOYEE",
                        employeeInfo: [],
                        status: "failure",
                        role: "kitchen"
                    })
                }else{
                    dispatch({
                        type: "GET_SERVER_DELIVERED",
                        server
                    })
                  
                }
                
            }).catch(err => {
                dispatch({
                    type:"GET_EMPLOYEE",
                    employeeInfo:[],
                    status:"failure",
                    role:"kitchen"
                })
            })
    }
}
export const DeliveringOrder = (orderId, branchId,token,role) => {
    return (dispatch, getState) => {
        axios.post(`${process.env.REACT_APP_API_URL}api/v1/server/update_order`, {

            "order_id": orderId,
            "delivery_status": true

        },{
            headers:{token:token,role:"server"}
        })

            .then(server => {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/server/get_non_delivered_orders/1?branch=${branchId}`,{
                    headers:{token:token,role:"server"}
                })
                    .then(server => {
                        if (server.data.msg==="Token error"  ||  server.data.status_code===450) {
                            dispatch({
                                type: "GET_EMPLOYEE",
                                employeeInfo: [],
                                status: "failure",
                                role: "kitchen"
                            })
                        }
                        else{
                            dispatch({
                                type: "UPDATED_PREP",
                                server
                            })
                            dispatch({
                                type:"SET_PAGE_DELIVERED",
                                page:1
                            })
                        }
                    }).catch(err => {
                        dispatch({
                            type:"GET_EMPLOYEE",
                            employeeInfo:[],
                            status:"failure",
                            role:"kitchen"
                        })
                    })

            }).catch(err => {
                dispatch({
                    type:"GET_EMPLOYEE",
                    employeeInfo:[],
                    status:"failure",
                    role:"kitchen"
                })
            })
    }
}
export const sendServerMessage = (phone,orderId,branchId,token) => {
    return (dispatch, getState) => {
        if(phone){
            axios.get(`http://pro.justonesms.com/submitsms.jsp?user=heebee&key=3aca39e23bXX&mobile=+91${phone}&message=test%20sms&senderid=ABCDEF&accusage=1/`,
            {"Access-Control-Allow-Origin": "*"})

            .then(server => {
                toast.success(`Message sent`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined, theme: "colored"
                });
                axios.post(`${process.env.REACT_APP_API_URL}api/v1/server/update_order`, {

                    "order_id": orderId,
                    "msg_status": true
        
                },{
                    headers:{token:token,role:"server"}
                }).then(server => {
                    axios.get(`${process.env.REACT_APP_API_URL}api/v1/server/get_non_delivered_orders/1?branch=${branchId}`,{
                        headers:{token:token,role:"server"}
                    })
                        .then(server => {
                        
                            if (server.data.msg==="Token error"  ||  server.data.status_code===450) {
                                dispatch({
                                    type: "GET_EMPLOYEE",
                                    employeeInfo: [],
                                    status: "failure",
                                    role: "kitchen"
                                })
                            }
                            else{
                                dispatch({
                                    type: "UPDATED_PREP",
                                    server
                                })
                                dispatch({
                                    type:"SET_PAGE_DELIVERED",
                                    page:1
                                })
                            }
                        }).catch(err => {
                            dispatch({
                                type:"GET_EMPLOYEE",
                                employeeInfo:[],
                                status:"failure",
                                role:"kitchen"
                            })
                        })
    
                }).catch(err => {
                    dispatch({
                        type:"GET_EMPLOYEE",
                        employeeInfo:[],
                        status:"failure",
                        role:"kitchen"
                    })
                })
                
            }).catch(err => {
                toast.info(err, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined, theme: "colored"
                });
               
            })
        }
        else{
            toast.error("No phone Number", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined, theme: "colored"
            });
            axios.post(`${process.env.REACT_APP_API_URL}api/v1/server/update_order`, {

                "order_id": orderId,
                "msg_status": true
    
            },{
                headers:{token:token,role:"server"}
            }).then(server => {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/server/get_non_delivered_orders/1?branch=${branchId}`,{
                    headers:{token:token,role:"server"}
                })
                    .then(server => {
                        if (server.data.msg==="Token error"  ||  server.data.status_code===450) {
                            dispatch({
                                type: "GET_EMPLOYEE",
                                employeeInfo: [],
                                status: "failure",
                                role: "kitchen"
                            })
                        }
                        else{
                            dispatch({
                                type: "UPDATED_PREP",
                                server
                            })
                            dispatch({
                                type:"SET_PAGE_DELIVERED",
                                page:1
                            })
                        }
                    }).catch(err => {
                        dispatch({
                            type:"GET_EMPLOYEE",
                            employeeInfo:[],
                            status:"failure",
                            role:"kitchen"
                        })
                    })

            }).catch(err => {
                dispatch({
                    type:"GET_EMPLOYEE",
                    employeeInfo:[],
                    status:"failure",
                    role:"kitchen"
                })
            })
        }
       
    }
}
export const sendMessage = (phone) => {
    return (dispatch, getState) => {
        if(phone){
            axios.get(`http://pro.justonesms.com/submitsms.jsp?user=heebee&key=3aca39e23bXX&mobile=+91${phone}&message=test%20sms&senderid=ABCDEF&accusage=1/`,
            {"Access-Control-Allow-Origin": "*"} )

            .then(server => {
                toast.info(`Message sent`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined, theme: "colored"
                });
            }).catch(err => {
                toast.info(err, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined, theme: "colored"
                });
               
            })
        }
       
    }
}



export const DeliveringOrderItem = (orderItemId, branchId,token,role) => {
    return (dispatch, getState) => {
        axios.post(`${process.env.REACT_APP_API_URL}api/v1/server/update_order_items`, {

            "order_items_id": orderItemId,
            "delivery_status": true

        },{
            headers:{token:token,role:"server"}
        })

            .then(server => {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/server/get_non_delivered_orders/1?branch=${branchId}`,{
                    headers:{token:token,role:"server"}
                })
                    .then(server => {
                        if (server.data.msg==="Token error"  ||  server.data.status_code===450) {
                            dispatch({
                                type: "GET_EMPLOYEE",
                                employeeInfo: [],
                                status: "failure",
                                role: "kitchen"
                            })
                        }else{
                            dispatch({
                                type: "UPDATED_PREP_ITEM",
                                server
                            })
                            dispatch({
                                type:"SET_PAGE_DELIVERED",
                                page:1})
                        }
                        
                    }).catch(err => {
                      
                        dispatch({
                            type:"GET_EMPLOYEE",
                            employeeInfo:[],
                            status:"failure",
                            role:"kitchen"
                        })
                    })

            }).catch(err => {
                dispatch({
                    type:"GET_EMPLOYEE",
                    employeeInfo:[],
                    status:"failure",
                    role:"kitchen"
                })
            })
    }
}
export const DeliveringAllOrderItem = (orderId, branchId,token,role) => {
   
    return (dispatch, getState) => {
        axios.post(`${process.env.REACT_APP_API_URL}api/v1/server/update_batch_delivery_status`, {
            "order_id": orderId,
            "delivery_status": true

        },{
            headers:{token:token,role:"server"}
        })

            .then(server => {
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/server/get_non_delivered_orders/1?branch=${branchId}`,{
                    headers:{token:token,role:"server"}
                })
                    .then(server => {

                        if (server.data.msg==="Token error"  ||  server.data.status_code===450) {
                            dispatch({
                                type: "GET_EMPLOYEE",
                                employeeInfo: [],
                                status: "failure",
                                role: "kitchen"
                            })
                        }else{
                            dispatch({
                                type: "UPDATED_PREP_ITEM_All",
                                server
                            })
                            dispatch({
                                type:"SET_PAGE_DELIVERED",
                                page:1
                            })
                        }
                        
                    }).catch(err => {
                        dispatch({
                            type:"GET_EMPLOYEE",
                            employeeInfo:[],
                            status:"failure",
                            role:"kitchen"
                        })
                    })

            }).catch(err => {
                dispatch({
                    type:"GET_EMPLOYEE",
                    employeeInfo:[],
                    status:"failure",
                    role:"kitchen"
                })
            })
    }
}
