import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import logo from "../../assets/images/logo.png"
import { useSelector, useDispatch } from 'react-redux';
import { getCompletedOrders } from '../../store/actions/serverAction';
import LoaderLarge from '../../loader';
import { IoRefreshCircle } from "react-icons/io5";
import { getProducts } from '../../store/actions/productAction';
import { UpdateOrderItems, UpdateAllOrderItems, getOrderOnCheckout, CompletedOrderUpdate } from '../../store/actions/serverAction';
import { FaCheckCircle } from "react-icons/fa"
import { setEmployeeToken } from '../../store/actions/authenticationAction';
import { toast } from 'react-toastify';
import CompletedFoot from './CompletedFoot';
import StickyNote from '../StickyNote';
import { Button, Modal } from 'react-bootstrap';
import RenderSmallCompleted from './RenderSmallCompleted';
import CompletedBigcard from './CompletedBigcard';
import { setPageCompleted } from '../../store/actions/serverpagination';
import { SocketContext } from '../../context/socket.context'
import notification from "../../assets/sound/notified.wav";


const CompletedOrders = (props) => {
    const socket = useContext(SocketContext)
    const dispatch = useDispatch();
    const emp_info = useSelector(state => state.emp_info);
    const page = useSelector(state => state.server)
    const completed = useSelector(state => state.completed)

    useEffect(async () => {
        // get all kitchen orders on refresh or starting of the page
        
        if (emp_info) {
            dispatch(getProducts(emp_info.employeeinfo.data.token))
            console.log("emitting get_completed_orders")
            socket.emit("get_completed_orders", {
                // page : page,
                branch: emp_info.employeeinfo.data.branch_id,
                token: emp_info.employeeinfo.data.token,
                role: emp_info.role
            })

        }
        return () => socket.disconnect();
    }, []);

    useEffect(() => {
        socket.on("recieve_completed_orders", (data) => {
            console.log('recieve_completed_orders', data)
            dispatch(
                getCompletedOrders(
                    data
                )
            );
        })

        socket.on("checkout", (data)=> {
            console.log(data.message, data.order_id)
            checkout_handler(data.order_id)
        })

        socket.on('update_order_items_success', (result) => {
            console.log('update_order_items_success', result)
            dispatch(UpdateOrderItems(result.order_id, result.data, completed, result.status_code))
            if(result.status_code == 201){
                toast.info(`Order Number ${result.order_id} Delivered`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            
        })

        socket.on("update_order_items_success_server", (result) => {
            console.log('update_order_items_success_server', result)
            dispatch(CompletedOrderUpdate(result.order_id, result.data, completed, result.status_code))
        })
      
        socket.on('update_order_items_failure', (result)=>{
            toast.error(result.msg, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
        })

        socket.on('update_all_order_items_success', (result) => {
            console.log('update_all_order_items_success', result)
            dispatch(UpdateAllOrderItems(result.data, completed))
            toast.info(`Order Number ${result.data.order_id} Delivered`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        })

        socket.on('update_all_order_items_failure', (result)=>{
            toast.error(result.msg, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored"
            })
        })

        return () => {
            socket.removeAllListeners();
        }

    }, [socket])

    const audioPlayer = useRef(null);
    function playAudio() {
        audioPlayer.current.play();
    }

    // socket handler for any checkout that takes place
    const checkout_handler = (order_id) => {
        console.log('Order id in handler', order_id)
        dispatch(getOrderOnCheckout(order_id, emp_info.employeeinfo.data.branch_id, emp_info.employeeinfo.data.token, completed))
        
        toast.success(`NEW ORDER RECEIVED`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        playAudio();
    }

    const refresh = () => {
        // window.location.reload(false);
        if (emp_info) {
            dispatch(getProducts(emp_info.employeeinfo.data.token))
            console.log("emitting get_completed_orders")
            socket.emit("get_completed_orders", {
                // page : page,
                branch: emp_info.employeeinfo.data.branch_id,
                token: emp_info.employeeinfo.data.token,
                role: emp_info.role
            })

        }
        return
    }


    const BigCard = () => {

        if (Object.keys(completed).length > 0) {
            if (completed.data.status === "success") {
                return completed.data.data.map((item, index) => {
                    return <CompletedBigcard key={item.order_id} item={item} index={index} />
                })
            }
            else {
                return <StickyNote data="All orders delivered" />
            }

        }
        else {
            return <LoaderLarge />
        }

    }
    return (
        <React.Fragment>
            <nav className="container-fluid">
                <div className='row pt-2 pb-2' style={{ whiteSpace: "nowrap", borderRadius: "10px", margin: "2px", border: "1px solid rgba(0,0,0,.125)", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                    <div className="col-sm-4 col-xs-12 d-flex justify-content-start align-items-center"><img width="40px" src={logo} alt="logo" style={{ marginTop: "10px", marginBottom: "10px" }} />
                        <span className='main-role mr cursive'>Completed Orders</span>
                    </div>
                    <div className='col-sm-8 col-xs-12'>
                        <div style={{ height: "100%" }} className="d-flex justify-content-end align-items-center nav-con ">
                            <Link to="/server" className='navbar-link navContent active-button m-0'><FaCheckCircle /> <span>Completed</span></Link>
                            <Link to="/server/delivered" className='navbar-link navContent ready-button '><span>Delivered</span></Link>
                            <div className='navbar-link navContent ready-button me-2' onClick={() => {
                                dispatch(setEmployeeToken("server"))
                                window.location.href = '/'
                                }}><span>SignOut</span></div>
                            <div className='ready-button' onClick={() => refresh()}><IoRefreshCircle className='refreshButton' style={{ color: "#5c3706", fontWeight: "900" }} /></div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* navbar ends */}
            <div className='container-fluid'>
                <audio ref={audioPlayer} src={notification} />
                <div className='row'>
                    {BigCard()}
                </div>
            </div>
            <CompletedFoot />
        </React.Fragment>);
}
export default CompletedOrders;