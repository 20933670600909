import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../../assets/images/logo.png"
import { FaCheckCircle } from "react-icons/fa"
import coffee from "../../assets/images/coffee-cup-min.png";
import food from "../../assets/images/baristaIcon.png";
import "../../css/done.css";
import { FaUser } from "react-icons/fa"
import { IoRefreshCircle } from "react-icons/io5";
import { useSelector, useDispatch } from 'react-redux';
import { getReadyOrders } from '../../store/actions/kitchenAction';
import LoaderLarge from '../../loader';
import ReadyFooter from './ReadyFooter';
import { setEmployeeToken } from '../../store/actions/authenticationAction';
import StickyNote from '../StickyNote';
import ReadySmall from './ReadySmall';
import { setPageReady } from '../../store/actions/ReadyPagination';
import { SocketContext } from '../../context/socket.context';


const KitchenReady = (props) => {

    const socket = useContext(SocketContext)
    const dispatch = useDispatch();
    const emp_info = useSelector(state => state.emp_info);
    const role = useSelector(state => state.emp_info.role);
    const page = useSelector(state => state.readyPage)
    const ReadyOrders = useSelector(state => state.readyOrders);

    useEffect(async () => {
        // get all kitchen orders on refresh or starting of the page
        if (emp_info) {
            socket.emit("get_kitchen_ready_orders", {
                // page : page,
                branch: emp_info.employeeinfo.data.branch_id,
                token: emp_info.employeeinfo.data.token,
                role: emp_info.role
            })
        }
        return () => socket.disconnect();
    }, []);

    // Socket event handler
    useEffect(()=>{
        
        socket.on("recieve_kitchen_ready_orders", (data)=>{
            console.log('recieve_kitchen_ready_orders',data)
            dispatch(
                getReadyOrders(
                    data
                )
            );
            
        })
        return () => {
            socket.removeAllListeners();
        }
    }, [socket])

    const refresh = () => {
        // window.location.reload(false);
        if (emp_info) {
            socket.emit("get_kitchen_ready_orders", {
                // page : page,
                branch: emp_info.employeeinfo.data.branch_id,
                token: emp_info.employeeinfo.data.token,
                role: emp_info.role
            })
        }
        return
    }



    const getTime = (dateC) => {
        const date = dateC.split("T")[0];
        const time = (dateC.split("T")[1]).split("Z")[0];
        return (`${date}, ${(time).slice(0, -4)}`);
    }

    const renderSmall = (item) => {

        if (item) {
            return item.kitchen.map((orderItem, orderIndex) => {
                return <ReadySmall key={orderIndex} orderItem={orderItem} />
            });
        }
        else {
            return <></>
        }

    }
    const renderSmallBarista = (item) => {
        if (item) {
            return item.barista.map((orderItem, orderIndex) => {
                return <ReadySmall key={orderIndex} orderItem={orderItem} />

            });

        }
        else {
            return <></>
        }

    }
    const BigCard = () => {
        if (Object.keys(ReadyOrders).length !== 0) {
            console.log("ready orders in big card", ReadyOrders)
            if (ReadyOrders.data.status !== "failure") {
                return ReadyOrders.data.data.map((item, index) => {
                    const utcTime = item.createdAt;
                    const date = new Date(utcTime);
                    const istDate = date.toLocaleDateString('en-IN', { timeZone: 'Asia/Kolkata' });

                    return (
                        <div key={index} className='col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-3'>
                            <div className="card outer-scroll" style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}>
                                <div className="card-body">
                                    <div className='row mb-4'>
                                        <div className='col-8 orderNumber'>Order#{item.order_id}<br /><div className="orderTime" ><div className="col-12 orderTime" >Comments: {item.comment}</div>{istDate}, {new Date(item.createdAt).toLocaleTimeString()}<div style={{fontWeight: "bold", color: 'black'}}>{item.customer_name}</div>
                                            <div className="contact-info">{item.customer_no}</div>
                                        </div>

                                        </div>
                                        <div className='col-4 d-flex justify-content-end '>
                                            {item.order_type === "Take Away" ? <div className="bookmarkRibbon bookmarkRibbonTA"><span>T</span></div> : <div className="bookmarkRibbon bookmarkRibbonDI"><span>D</span></div>}<div className="userImage"  ><FaUser /></div></div>
                                    </div>
                                    <div className='innerScroll mt-2' style={{ overflowY: "auto" }}>
                                        {renderSmall(item)}
                                        {renderSmallBarista(item)}
                                    </div>

                                </div>
                            </div>

                        </div>
                    );

                })
            }
            else {
                return <StickyNote data="NO Ready Orders yet" />
            }

        }
        else {
            return <LoaderLarge />
        }

    }
    return (
        <React.Fragment>
            <nav className="container-fluid">
                <div className='row pt-2 pb-2' style={{ whiteSpace: "nowrap", borderRadius: "10px", margin: "2px", border: "1px solid rgba(0,0,0,.125)", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                    <div className="col-sm-6 col-xs-12 d-flex justify-content-start align-items-center"><img width="40px" src={logo} alt="logo" style={{ marginTop: "10px", marginBottom: "10px" }} />
                        <span className='main-role mr cursive'>Ready Orders</span>
                    </div>
                    <div className='col-sm-6 col-xs-12'>
                        <div style={{ height: "100%" }} className="d-flex justify-content-end align-items-center nav-con ">
                            {role === "kitchen" ? (<Link to="/" className='navbar-link navContent ready-button  m-0 '><img className='nav-image-logo' src={food} ></img><span>Active</span></Link>) : (<Link to="/barista" className='navbar-link navContent ready-button  m-0 '><img className='nav-image-logo' src={coffee}  ></img><span>Active</span></Link>)}
                            <Link to="/kitchen/ready" className='navbar-link navContent active-button '><FaCheckCircle /> <span>Ready</span></Link>
                            <div className='navbar-link navContent ready-button me-2' onClick={() => {
                                dispatch(setEmployeeToken(role))
                                window.location.href = '/';
                                }}><span>SignOut</span></div>
                            <div className='ready-button' onClick={() => refresh()}><IoRefreshCircle className='refreshButton' style={{ color: "#5c3706", fontWeight: "900" }} /></div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* navbar ends */}
            {
                Object.keys(ReadyOrders).length !== 0 ?
                <div className='container-fluid'>
                    <div className='row'>
                        {BigCard()}
                    </div>
                </div>
                :
                ""
            }
            
            <ReadyFooter />

        </React.Fragment>);
}
export default KitchenReady;