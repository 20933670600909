import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const FooterBarista = () => {
    const baristaOrder=useSelector(state=> state.barista);
    const [total, setTotal] = useState(0);
    useEffect(()=>{
        if(baristaOrder.data.status === 'success'){
            setTotal(baristaOrder.data.total_orders);
        }
        else if(baristaOrder.data.status === 'failure' && baristaOrder?.data?.data && isNaN(baristaOrder.data.total_orders)){
            let j = 0;
            for(let i = 0; i < baristaOrder?.data?.data.length; i++){
                if(baristaOrder?.data?.data[i].status === "Preparing"){
                    j++;
                }
            }
            setTotal(j++);
        }
        else{
            setTotal(0)
        }
    },[baristaOrder])
    console.log(total)
   
    return (
        <div className='container-fluid  mt-4 foot' style={{ backgroundColor: "#d1cbb9" }}>
            <div className='row' style={{ height: "90px" }}>
                <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
                    <div className='btn btn-secondary '>Total Orders: {!total ? 0 : total}</div>
                    
                </div>
            </div>
        </div>
    );
};

export default FooterBarista;