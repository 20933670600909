import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ReadyFooter = () => {
    
    const ReadyOrder = useSelector(state => state.readyOrders);
    
    return (
        <div className='container-fluid  mt-4 foot' style={{ backgroundColor: "#d1cbb9" }}>
            <div className='row' style={{ height: "90px" }}>
                <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
                <div className='btn btn-secondary '>Total Orders:{ReadyOrder.data?ReadyOrder.data.total_orders:0}</div>
                </div>
            </div>
        </div>
    );
};

export default ReadyFooter;