import React from 'react';
import "../css/noPending.css"
const StickyNote = ({data}) => {
    return (
        <>
            <ul id="stickyNote">
                <li>
                    <span>
                        <h2>HEEBEE</h2>
                        <p>{data}</p>
                    </span>
                </li>
            
            </ul>
        </>
    );
};

export default StickyNote;