import axios from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
export const getEmployeeToken=(email,password,role)=>{
    return (dispatch,getState)=>{
        axios.post(`${process.env.REACT_APP_API_URL}api/v1/${role}/${role}_login`,{
            "email":email,
            "password":password
        },{
            headers:{role:role}
        })
        .then(employeeInfo=>{    
            if(employeeInfo.data.status==="success"){
                localStorage.setItem("status","success");
                // localStorage.setItem("token",JSON.stringify(employeeInfo.data.token));
                
                axios.get(`${process.env.REACT_APP_API_URL}api/v1/${role}/employee_info?token=${employeeInfo.data.token}`,
                {
	              headers:{token:employeeInfo.data.token},
                  role:role
                })
                .then(empInfo=>{
                    console.log('getEmployeeToken success')
                    localStorage.setItem("employeeDetails",JSON.stringify(empInfo.data));
                    dispatch({
                        type:"GET_EMPLOYEE",
                        employeeInfo:empInfo.data,
                        status:"success",
                        role:role

                    })
                }).catch((err)=>{
                    console.log('getEmployeeToken error', err)
                    dispatch({
                        type:"GET_EMPLOYEE",
                        employeeInfo:[],
                        status:"failure",
                        role:role
                    })
                    localStorage.setItem("status","failure");
                })
            }
            else{
                console.log('getEmployeeToken error 2')
                localStorage.setItem("status","failure");
                toast.error(employeeInfo.data.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined, theme: "colored"
                });
                dispatch({
                    type:"GET_EMPLOYEE",
                    employeeInfo:employeeInfo.data.msg,
                    status:employeeInfo.data.status,
                    role:role
                })

            }
       
        }).catch((err)=> {
            // alert('employee token failure')
            console.log('getEmployeeToken error3', err)
            return <Navigate to="/" replace />           
           
        })
    }
}
export const setEmployeeToken=(role)=>{
    return (dispatch,getState)=>{
        dispatch({
            type:"SET_EMPLOYEE",
            employeeInfo:null,
            status:null,
            role:role
        })
        const kitchen=""
        const barista=kitchen
        const completedOrders=kitchen
        const server=kitchen
        dispatch({
            type: "GET_KITCHEN_ALL",
            kitchen
        })
        dispatch({
            type: "GET_BARISTA_ALL",
            barista
        })
        dispatch({
            type: "GET_COMPLETED_ORDERS",
            completedOrders
        })
        dispatch({
            type: "GET_SERVER_COMPLETE",
            server
        })
        dispatch({
            type: "GET_SERVER_DELIVERED",
            server
        })
    }
}