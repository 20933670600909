import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getServerDelivered } from '../../store/actions/serverAction';
import { setPageDelivered } from '../../store/actions/serverpagination';

const DeliveredFooter = () => {
    const DeliveredOrder = useSelector(state => state.delivered);
    
    return (
        <div className='container-fluid  mt-4 foot' style={{ backgroundColor: "#d1cbb9" }}>
            <div className='row' style={{ height: "90px" }}>
                <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
                <div className='btn btn-secondary '>Total Orders:{DeliveredOrder.data?DeliveredOrder.data.total_orders:0}</div>
                   
                </div>
            </div>
        </div>
    );
};

export default DeliveredFooter;