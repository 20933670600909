const initialState = {
    data : {
        status: '',
        data: [],
        total_orders: 0
    }
}
const kitchenReducer = (state = '', action) => {
    switch (action.type) {
        case "GET_KITCHEN_ALL":
            return {...action.kitchen};
        case "GET_CHECKOUT_ORDER":
            return {...action.kitchen};
        case "START_ORDER":
            return {...action.kitchen};
        case "UPDATE_ORDER_ITEMS":
            return {...action.kitchen};
        case "UPDATE_KITCHEN_ORDER_ITEMS_QUANTITY":
            return {...action.kitchen};    
        default:
            return state;
    }
}
export default kitchenReducer