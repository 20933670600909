const serverCompletedRed= (state ="", action) => {
    switch (action.type) {
        case "GET_SERVER_COMPLETE":
            return {...action.server};
        case "UPDATE_ORDER_ITEMS":
            return {...action.server};
        case "UPDATE_ALL_ORDER_ITEMS":
            return {...action.server};    
        case "GET_CHECKOUT_ORDER":
            return {...action.server};     
        case "UPDATED_PREP_ITEM_All":
            return {...action.server}
        default:
            return state;
    }
}
export default serverCompletedRed;