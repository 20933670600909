import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import "./index.css"
import App from './App';
import { Provider } from 'react-redux';
import {createStore,applyMiddleware,compose} from 'redux'
import thunk from 'redux-thunk';
import rootReducer from './store/Reducers/rootReducer';
import "./assets/fonts/Inter-VariableFont_slnt.ttf";
import "./assets/fonts/ReenieBeanie-Regular.ttf"
function saveToLocalStorage(state) {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('state', serializedState);
}

function loadFromLocalStorage() {
const serializedState = localStorage.getItem('state');
if (serializedState === null) return undefined;
 return JSON.parse(serializedState);
}
const presistedState = loadFromLocalStorage();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store =createStore(rootReducer,presistedState,composeEnhancers(applyMiddleware(thunk)))
store.subscribe(() => {
  saveToLocalStorage(store.getState())
});
ReactDOM.render(

  <Provider store={store}>
        <App />
  </Provider>

    ,
  document.getElementById('root')
);

