import React, { useEffect, useState } from 'react';
import "../../css/login.css"
import { Link, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeToken } from '../../store/actions/authenticationAction';
import logo from "../../assets/images/logo.png";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("kitchen");
    const dispatch = useDispatch();
    const state = useSelector(state => state.emp_info);

    useEffect(() => {
    }, [state]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(getEmployeeToken(email, password,role));
        setEmail("");
        setPassword("")
    }
    if (state.status === "success") {
        if(state.role==="kitchen"){
            return <Navigate to="/kitchen"></Navigate>
        } 
        if(state.role==="server"){
            return <Navigate to="/server"></Navigate>
        } 
        else{
            return <Navigate to="/barista"></Navigate>
        }
        
    }
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-4 col-md-4 col-sm-12 px-0'>
                        <div className='login-card d-flex flex-column justify-content-center align-items-center'>
                            <div className='login-card-header text-center mb-4'>
                                {/* <img src="https://lh3.googleusercontent.com/d/1JxtfqjgBhDqXpcqXXLqt9MBFwV47NTIS" height="40" className='mb-3' alt="..." /> */}
                                <img
                                    width="40px"
                                    src={logo}
                                    alt="logo"
                                    style={{ marginTop: "10px", marginBottom: "10px" }}
                                />
                                <h4 className="login-head">Welcome Back!</h4>
                                <p className="small text-muted">Log in to continue to HeeBee Web Pos.</p>
                            </div>
                            <div className='login-form'>
                                <form>
                                    <div className="form-floating mb-3">
                                        <input value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                                        <label htmlFor="floatingInput">Email address</label>
                                    </div>
                                    <div className="form-floating mb-4">
                                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" id="floatingPassword" placeholder="Password" />
                                        <label htmlFor="floatingPassword">Password</label>
                                    </div>
                                    
                                    <div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value={"kitchen"} onChange={()=>setRole("kitchen")}  defaultChecked/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Kitchen
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value={"barista"} onChange={()=>setRole("barista")}/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                Barista
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" value={"server"} onChange={()=>setRole("server")} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                Server
                                            </label>
                                        </div>

                                        <div className='mb-4 mt-4 d-grid w-100' style={{paddingLeft:'5%',borderRadius:"10px"}}>
                                            <button type="submit" className='btn btn-lg btn-login'  onClick={e => handleSubmit(e)} >Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-8 col-sm-12 px-0 '>
                        <div className="authentication-bg d-none d-md-block">
                            <div className="bg-overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
