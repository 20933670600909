import axios from "axios";
import { url } from "../../Apis";

export const getProducts=(token)=>{
    console.log('Dispatching Get Products...')
    return (dispatch,getState)=>{
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/webpos/get_all_products`,{
            headers:{token:token}
        })
        .then(products=>{    
            let finalData={}
            products.data.data.map((item,index)=>{
                finalData[item.product_id]=item;
              })
        dispatch({
                type:"GET_PRODUCT",
                finalData
            })
        }).catch(err=>{
            console.log('getProducts error', err)
            dispatch({
                type:"GET_EMPLOYEE",
                employeeInfo:[],
                status:"failure",
                role:"kitchen"
            })
        })
    }
}