import React, { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { HiBadgeCheck } from "react-icons/hi";
import { AiFillCopyrightCircle } from "react-icons/ai"
import { DeliveringOrderItem } from '../../store/actions/serverAction';
import { SocketContext } from '../../context/socket.context';

const RenderSmallCompleted = ({ orderItem, order_id }) => {
    // MOdal popup vars
    const socket = useContext(SocketContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // MOdal popup vars end     
    const AllProducts = useSelector(state => state.product);
    const itemDetails = AllProducts[orderItem.product_id];
    const emp_info = useSelector(state => state.emp_info);
    const dispatch = useDispatch();
    const description = () => {
        if (orderItem.add_ons) {
            return Object.keys(orderItem.add_ons).map((key, i) => {
                if (Array.isArray(orderItem.add_ons[key])) {
                    var output = ''
                    for (var j = 0; j < orderItem.add_ons[key].length; j++) {
                        output = output + orderItem.add_ons[key][j]['value'] + ' ,'
                    }
                    // console.log('output', output)
                    return <span key={i}>{key}:{output.substring(0, output.length - 2)} ,</span>
                } else {
                    return <span key={i}>{key}:{orderItem.add_ons[key]} ,</span>
                }

            }


            )
        }

    }
    const handleprep = (e) => {
        // dispatch(UpdateOrderItems(orderItem.order_items_id, emp_info.employeeinfo.data.branch_id, emp_info.employeeinfo.data.token, emp_info.role))
        if(orderItem.status!=='Completed'){
            return
        }
        socket.emit("update_order_items", {
            branch: emp_info.employeeinfo.data.branch_id,
            order_id: order_id,
            data: {
                "order_items_id": orderItem.order_items_id,
                "delivery_status": true
            }
        })
        e.stopPropagation();
    }

    const newId = uuid().slice(0, 8);
    const displ = () => {
        if (itemDetails) {

            return (
                <div key={newId} >
                    <div className='d-flex mt-3' style={orderItem.status!=='Completed' ? {opacity: '0.5', position: 'relative'} : {opacity: '1', position: 'relative'}} onClick={handleShow}>
                        <div className='w-25 d-flex justify-content-start align-items-start p-1'><img className="DoneImage" style={{ borderRadius: "10px" }} src={itemDetails.product_list.card_img} alt={newId + "IMAGE"}></img></div>
                        <div className='w-75 ready-small-content'>
                            <div className="order-card-title cursive pe-0">{orderItem.product_name}  {`(${orderItem.order_sku})`}</div>
                            <div className="order-card-text cursive">{description()}</div>
                            <span className="btn btn-primary btn-sm quan mt-1" >{orderItem.quantity}</span>
                            {orderItem.delivery_status === true ? <div className="orderItem-done" style={{ color: "#0B9AD9" }}><HiBadgeCheck /></div> : <span className='btn btn-primary btn-sm quan mt-1 ms-2' onClick={handleprep} >Delivered?</span>}
                            {orderItem.comment ? <AiFillCopyrightCircle className='commentSymbol' /> : <></>}
                        </div></div>
                    <>
                        <Modal className='remove-border' show={show} onHide={handleClose} centered>
                            <Modal.Header className=" bg-secondary text-accent remove-border" closeButton>
                                <Modal.Title >Comments</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className=" bg-accent text-secondary remove-border">{orderItem.comment ? <span>{orderItem.comment}</span> : <span>No comments</span>}</Modal.Body>

                        </Modal>
                    </>
                </div>
            );
        }
    }
    return (<React.Fragment>{displ()}</React.Fragment>);
};

export default RenderSmallCompleted;