import kitchenReducer from "./kitchenReducer";
import productReducer from "./productsReducer";
import authReducer from "./authReducer";
import readyReducer from "./readyReducer";
import paginationReducer from "./paginationReducer";
import ReadyPaginationReducer from "./ReadypaginationReducer";
import serverCompletedRed from "./serverCompletedRed";
import serverReducer from "./serverReducer";
import { combineReducers } from "redux";
import ServerPaginationReducer from "./serverPaginationReducer";
import baristaReducer from "./baristaReducer";

const rootReducer =combineReducers({
    kitchen:kitchenReducer, 
    barista:baristaReducer,
    product:productReducer,
    emp_info:authReducer,
    readyOrders:readyReducer,
    page:paginationReducer,
    readyPage:ReadyPaginationReducer,
    completed:serverCompletedRed,
    delivered:serverReducer,
    serverPage: ServerPaginationReducer
})
export default rootReducer;