import React from 'react';
import { useRef } from "react";
import { HiBadgeCheck, HiPrinter } from "react-icons/hi";
import { useSelector } from 'react-redux';
import def from "../../assets/images/default.jpg"
import { useReactToPrint } from "react-to-print";
import { OrderItemPrintDisabled } from "./OrderItemPrintDisabled"

const RenderSmallDisabled = ({newId,orderItem, item}) => {
    const AllProducts = useSelector(state => state.product);
    const itemDetails = AllProducts[orderItem.product_id];
    const componentref = useRef();
    const description=()=>{
        // console.log('rendersmalldisabled', orderItem.add_ons)
        if (orderItem.add_ons) {
            return Object.keys(orderItem.add_ons).map((key, i) => {
                if (Array.isArray(orderItem.add_ons[key])) {
                    var output = ''
                    for (var j = 0; j < orderItem.add_ons[key].length; j++) {
                        output = output + orderItem.add_ons[key][j]['value'] + ' ,'
                    }
                    // console.log('output', output)
                    return <span key={i}>{key}:{output.substring(0, output.length - 2)} ,</span>
                } else {
                    return <span key={i}>{key}:{orderItem.add_ons[key]} ,</span>
                }

            }


            )
        }
    }

    const printKT = (e) =>{
        e.stopPropagation();
        print()
    } 
      
    const print = useReactToPrint({
        content: () => componentref.current,
    });

    return (
        <div key={newId} className='d-flex mt-3' style={{ position: 'relative'}}>
            <div className='w-25 d-flex justify-content-start align-items-start p-1' style={{opacity: ".6"}}><img className="DoneImage" style={{ borderRadius: "10px" }} src={itemDetails?itemDetails.product_list.card_img:def} alt={newId + "IMAGE"}></img></div>
            <div className='w-75 ready-small-content'>
                <div className="order-card-title cursive" style={{opacity: ".6"}}>{orderItem.product_name} {`(${orderItem.order_sku})`}</div>
                <div className="foodSign coffeeSign" style={{opacity: ".6"}}><div className='circle-coffee'></div></div>
                <div className="order-card-text " style={{opacity: ".6"}}>{description()}</div>
                <button className="btn btn-warning btn-sm quan mt-1" disabled style={{opacity: ".6"}} >Qty:{orderItem.quantity}</button>
                {orderItem.status ===null ? <button className="btn btn-warning btn-sm ms-2 mt-1" disabled style={{opacity: ".6"}}>Preparing</button> : <div className="orderItem-done" style={{color:"#eec510", opacity:"0.6"}}><HiBadgeCheck /></div>}
                <div
                    className="btn btn-outline-success btn-sm ms-2 quan mt-1" 
                    onClick={printKT}
                    >
                        <HiPrinter/>
                </div>
                <div className="visually-hidden-focusable p-1">
                    <OrderItemPrintDisabled
                        componentref={componentref}
                        order={{
                            orderItem: orderItem,
                            item: item
                        }}
                    />
                </div>
            </div>
        </div>
    )
};

export default RenderSmallDisabled;