import React, { useState } from "react";
import { useSelector } from "react-redux";


export const AllOrderItemPrint = ({ order, componentref }) => {
    // console.log('order', order)
    const description = (orderItem) => {
        // console.log(orderItem)
        if (orderItem.add_ons) {
            return Object.keys(orderItem.add_ons).map((key, i) => {
                if (Array.isArray(orderItem.add_ons[key])) {
                    var output = "";
                    for (var j = 0; j < orderItem.add_ons[key].length; j++) {
                        output = output + orderItem.add_ons[key][j]["value"] + " ,";
                    }
                    return (
                        <span key={i}>
                            {output.substring(0, output.length - 2)} ,
                        </span>
                    );
                } else {
                    return (
                        <span key={i}>
                            {orderItem.add_ons[key]} ,
                        </span>
                    );
                }
            });
        }
    };


    return (
        <>
            <div ref={componentref} className="px-1">
                {
                    order.kitchen.flatMap((orderItem, orderIndex) => {
                        if(orderItem.quantity !== 1){
                            var list = []
                            for(var i=0; i< orderItem.quantity; i++){
                                list.push(
                                    <div key={orderIndex.toString() + '-' + (i+1).toString()}>
                                        <div className="flex-col py-2 my-2 extra-small">
                                            <div className="d-flex flex-row justify-content-between font-thin text-xs">
                                                <div className="text-gray-900 font-bold">{order.customer_name}</div>
                                                <div className="font-bold">
                                                    {order.order_id}
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-between font-thin text-xs">
                                                <div className="text-gray-900 font-bold">{order.order_type}</div>
                                                <div className="font-bold">
                                                    {order.comment}
                                                </div>
                                            </div>
                                            
                                            <div className="d-flex flex-row justify-content-between font-thin text-xs">
                                                <div className="text-gray-900 font-bold">{orderItem.product_name}-{description(orderItem)}</div>
                                            </div>
                                            
                                        </div>
                                        <div className="pagebreak"> </div>
                                    </div>
                                )
                            }
                            return list
                        }
                        else{
                            return [(
                                <div key={orderIndex}>
                                    <div className="flex-col py-2 my-2 extra-small">
                                        <div className="d-flex flex-row justify-content-between font-thin text-xs">
                                            <div className="text-gray-900 font-bold">{order.customer_name}</div>
                                            <div className="font-bold">
                                                {order.order_id}
                                            </div>
                                        </div>
    
                                        <div className="d-flex justify-content-between font-thin text-xs">
                                            <div className="text-gray-900 font-bold">{order.order_type}</div>
                                            <div className="font-bold">
                                                {order.comment}
                                            </div>
                                        </div>
                                        
                                        <div className="d-flex flex-row justify-content-between font-thin text-xs">
                                            <div className="text-gray-900 font-bold">{orderItem.product_name}-{description(orderItem)}</div>
                                        </div>
                                        
                                    </div>
                                    <div className="pagebreak"> </div>
                                </div>
                                
                            )]
                        }
                        
                        
                    })
                }
                
                
            </div>
        </>
    );
};