
import axios from "axios";

export const getBaristaOrders = (data) => {
    console.log('Dispatching getBaristaOrders....', data)
    return (dispatch, getState) => {
        
        dispatch({
            type: "GET_BARISTA_ALL",
            barista: {data: data}
        })
    }
}

export const getReadyOrders = (data) => {
    console.log('Dispatching getReadyOrders....', data)
    return (dispatch, getState) => {
        
        dispatch({
            type: "GET_COMPLETED_ORDERS",
            completedOrders: {data: data}
        })
    }
}

export const getOrderOnCheckout = (order_id, branchId, token, baristaOrder) => {
    console.log("dispatching getOrderOnCheckout...", order_id);
    return (dispatch, getState) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/barista/get_order_by_order_id/${order_id}?branch=${branchId}`, {
            headers: { token: token, role: "barista" }
        })
            .then(barista => {
                
                var baristaNewOrder = {...baristaOrder}
                if (barista.data.msg === "Token error" || barista.data.status_code === 450) {
                    dispatch({
                        type: "GET_EMPLOYEE",
                        employeeInfo: [],
                        status: "failure",
                        role: "barista"
                    })
                }
                else if(barista.data.msg == "failure"){
                    // api failed to fetch data
                    console.log('Api failed to fetch order via order id')
                }
                else {
                    if (!baristaNewOrder.data) {
                        baristaNewOrder.data = {};
                    }
                    if (!Array.isArray(baristaNewOrder.data.data)) {
                        baristaNewOrder.data.data = [];
                    }
                    baristaNewOrder.data.data.push(barista.data.data[0])
                    baristaNewOrder.data.total_orders = baristaNewOrder.data.total_orders + 1;
                    console.log('baristaOrderRevised', baristaNewOrder)
                    dispatch({
                        type: "GET_CHECKOUT_ORDER",
                        barista : baristaNewOrder
                    })
                  
                }

            }).catch(err => {
                dispatch({
                    type: "GET_EMPLOYEE",
                    employeeInfo: [],
                    status: "failure",
                    role: "barista"
                })
            })
    }
}

export const startBaristaOrders = (order_id, completed_time, start_time, baristaOrder) => {
    return (dispatch, getState) => {
        console.log('Dispatching startBaristaOrders....', (order_id, completed_time, start_time, baristaOrder))
        // var kitchen = baristaOrder.data.data.map( (item, index)=>{
        //     if(order_id == item.order_id){
        //         item.completed_time = completed_time;
        //         item.start_time = start_time
        //     }
        // })
        var baristaNewOrder = {...baristaOrder}
        if(Object.keys(baristaOrder).length === 0){
            const kitchen = JSON.parse(localStorage.getItem('state'));
            baristaNewOrder = {...kitchen.barista}
            window.location.reload();
        }
        for (var i = 0; i< baristaNewOrder.data.data.length; i++){
            if(baristaNewOrder.data.data[i].order_id == order_id){
                baristaNewOrder.data.data[i].completed_time = completed_time;
                baristaNewOrder.data.data[i].start_time = start_time;
            }
        }

        console.log('startbaristaOrders response...', baristaNewOrder)

        dispatch({
            type: "START_ORDER",
            barista: baristaNewOrder
        })
        

    }
}


export const UpdateOrderItems = (order_id, data, baristaOrder, status) => {
    console.log('Dispatching UpdateOrderItems....', (order_id, data, baristaOrder))
    return (dispatch, getState) => {
        var baristaNewOrder = {...baristaOrder}
        if(Object.keys(baristaOrder).length === 0){
            const kitchen = JSON.parse(localStorage.getItem('state'));
            baristaNewOrder = {...kitchen.barista}
            window.location.reload();
        }
        for (var i = 0; i< baristaNewOrder.data.data.length; i++){
            if(baristaNewOrder.data.data[i].order_id == order_id){
                
                if(status == 201){
                    baristaNewOrder.data.data[i].status = "Completed";
                    baristaNewOrder.data.total_orders = baristaNewOrder.data.total_orders - 1;
                    for(var j=0; j<baristaNewOrder.data.data[i].barista.length; j++){
                        
                        
                        if(baristaNewOrder.data.data[i].barista[j].order_items_id == data.order_items_id){
                            
                            baristaNewOrder.data.data[i].barista[j].status = data.status;
                            baristaNewOrder.data.data[i].barista[j].completed_time = data.completed_time;
                            baristaNewOrder.data.data[i].barista[j].prepared_by = data.prepared_by;
                            baristaNewOrder.data.data[i].barista[j].quantity_completed = data.quantity_completed;
                        }
                    }
                }else if(status == 202){
                    baristaNewOrder.data.data.splice(i,1);
                    baristaNewOrder.data.total_orders = baristaNewOrder.data.total_orders - 1
                }else{
                    for(var j=0; j<baristaNewOrder.data.data[i].barista.length; j++){
                        
                        
                        if(baristaNewOrder.data.data[i].barista[j].order_items_id == data.order_items_id){
                            
                            baristaNewOrder.data.data[i].barista[j].status = data.status;
                            baristaNewOrder.data.data[i].barista[j].completed_time = data.completed_time;
                            baristaNewOrder.data.data[i].barista[j].prepared_by = data.prepared_by;
                            baristaNewOrder.data.data[i].barista[j].quantity_completed = data.quantity_completed;
                        }
                    }
                }
                
            }
        }


        dispatch({
            type: "UPDATE_ORDER_ITEMS",
            barista: baristaNewOrder
        })

    }
}





// import axios from "axios";

// export const getBaristaOrders = (page, branchId, token, role) => {
//     return (dispatch, getState) => {
//         axios.get(`${process.env.REACT_APP_API_URL}api/v1/barista/get_preparing_orders/${page}?branch=${branchId}`, {
//             headers: { token: token, role: "barista" }
//         })
//             .then(barista => {
            
//                 if (barista.data.msg === "Token error" || barista.data.status_code === 450) {
//                     dispatch({
//                         type: "GET_EMPLOYEE",
//                         employeeInfo: [],
//                         status: "failure",
//                         role: "barista"
//                     })
//                 }
//                 else {
//                     dispatch({
//                         type: "GET_BARISTA_ALL",
//                         barista
//                     })
                  
//                 }

//             }).catch(err => {
//                 dispatch({
//                     type: "GET_EMPLOYEE",
//                     employeeInfo: [],
//                     status: "failure",
//                     role: "barista"
//                 })
//             })
//     }
// }
// export const startBaristaOrders = (page, orderId, start_time, branchId, token, role) => {
//     return (dispatch, getState) => {
//         axios.post(`${process.env.REACT_APP_API_URL}api/v1/barista/update_order`, {
//             order_id: orderId,
//             "paid": true,
//             "status": "Preparing",
//             "completed_time": null,
//             "start_time": start_time
//         }, {
//             headers: { token: token, role: "barista" }
//         })

//             .then(barista => {
//                 axios.get(`${process.env.REACT_APP_API_URL}api/v1/barista/get_preparing_orders/1?branch=${branchId}`, {
//                     headers: { token: token, role: "barista" }
//                 })
//                     .then(barista => {
//                         if (barista.data.msg === "Token error" || barista.data.status_code === 450) {
//                             dispatch({
//                                 type: "GET_EMPLOYEE",
//                                 employeeInfo: [],
//                                 status: "failure",
//                                 role: "barista"
//                             })
//                         }
//                         else {
//                             dispatch({
//                                 type: "UPDATE_BARISTA_START",
//                                 barista
//                             })
//                             dispatch({
//                                 type:"SET_PAGE",
//                                 page:1
//                             })
//                         }

//                     }).catch(err => {
//                         dispatch({
//                             type: "GET_EMPLOYEE",
//                             employeeInfo: [],
//                             status: "failure",
//                             role: "barista"
//                         })
//                     })

//             }).catch(err => {
//                 dispatch({
//                     type: "GET_EMPLOYEE",
//                     employeeInfo: [],
//                     status: "failure",
//                     role: "barista"
//                 })
//             })
//     }
// }
// export const completeBaristaOrders = (page, branchId, orderId, start_time, completedTime, token, role) => {
//     return (dispatch, getState) => {
//         axios.post(`${process.env.REACT_APP_API_URL}api/v1/barista/update_order`, {
//             order_id: orderId,
//             "paid": true,
//             "status": "Completed",
//             "completed_time": completedTime,
//             "start_time": start_time
//         }, {
//             headers: { token: token, role: "barista" }
//         })
//             .then(barista => {
//                 axios.get(`${process.env.REACT_APP_API_URL}api/v1/barista/get_preparing_orders/1?branch=${branchId}`, {
//                     headers: { token: token, role: "barista" }
//                 })
//                     .then(barista => {
//                         if (barista.data.msg === "Token error" || barista.data.status_code === 450) {
//                             dispatch({
//                                 type: "GET_EMPLOYEE",
//                                 employeeInfo: [],
//                                 status: "failure",
//                                 role: "barista"
//                             })
//                         }
//                         else {
//                             dispatch({
//                                 type: "UPDATE_BARISTA_COMPLETED",
//                                 barista
//                             })
//                             dispatch({
//                                 type:"SET_PAGE",
//                                 page:1
//                             })
//                         }

//                     }).catch(err => {
//                         dispatch({
//                             type: "GET_EMPLOYEE",
//                             employeeInfo: [],
//                             status: "failure",
//                             role: "barista"
//                         })
//                     })

//             }).catch(err => {
//                 dispatch({
//                     type: "GET_EMPLOYEE",
//                     employeeInfo: [],
//                     status: "failure",
//                     role: "barista"
//                 })
//             })
//     }
// }
// export const UpdateOrderItemsQuantityBarista = (page, branchId, order_items_id, quantityDone, token, role) => {
//     return (dispatch, getState) => {
//         axios.post(`${process.env.REACT_APP_API_URL}api/v1/barista/update_order_items`, {
//             "order_items_id": order_items_id,
//             "status": "Preparing",
//             "completed_time": null,
//             "prepared_by": null,
//             "quantity_completed": quantityDone
//         }, { headers: { token: token, role: "barista" } })
//             .then(barista => {
//                 axios.get(`${process.env.REACT_APP_API_URL}api/v1/barista/get_preparing_orders/1?branch=${branchId}`, {
//                     headers: { token: token, role: "barista" }
//                 })
//                     .then(barista => {
                     
//                         if (barista.data.msg === "Token error" || barista.data.status_code === 450) {
//                             dispatch({
//                                 type: "GET_EMPLOYEE",
//                                 employeeInfo: [],
//                                 status: "failure",
//                                 role: "barista"
//                             })
//                         } else {
//                             dispatch({
//                                 type: "UPDATE_BARISTA_ORDER_ITEMS_QUANTITY",
//                                 barista
//                             })
//                             dispatch({
//                                 type:"SET_PAGE",
//                                 page:1
//                             })
//                         }

//                     }).catch(err => {
                
//                         dispatch({
//                             type: "GET_EMPLOYEE",
//                             employeeInfo: [],
//                             status: "failure",
//                             role: "barista"
//                         })
//                     })

//             }).catch(err => {
//                 dispatch({
//                     type: "GET_EMPLOYEE",
//                     employeeInfo: [],
//                     status: "failure",
//                     role: "barista"
//                 })
//             })
//     }
// }

// export const UpdateOrderItemsBarista = (page, branchId, order_items_id, completedTime, preparedBy, quantityDone, token, role) => {

//     return (dispatch, getState) => {
//         axios.post(`${process.env.REACT_APP_API_URL}api/v1/barista/update_order_items`, {
//             "order_items_id": order_items_id,
//             "status": "Completed",
//             "completed_time": completedTime,
//             "prepared_by": preparedBy,
//             "quantity_completed": quantityDone
//         }, { headers: { token: token, role: "barista" } })
//             .then(barista => {
//                 axios.get(`${process.env.REACT_APP_API_URL}api/v1/barista/get_preparing_orders/1?branch=${branchId}`, {
//                     headers: { token: token, role: "barista" }
//                 })
//                     .then(barista => {
//                         if (barista.data.msg === "Token error" || barista.data.status_code === 450) {
//                             dispatch({
//                                 type: "GET_EMPLOYEE",
//                                 employeeInfo: [],
//                                 status: "failure",
//                                 role: "barista"
//                             })
//                         }
//                         else {
//                             dispatch({
//                                 type: "UPDATE_BARISTA_ORDER_ITEMS_COMPLETED",
//                                 barista
//                             })
//                             dispatch({
//                                 type:"SET_PAGE",
//                                 page:1
//                             })
//                         }
//                     }).catch(err => {
   
//                         dispatch({
//                             type: "GET_EMPLOYEE",
//                             employeeInfo: [],
//                             status: "failure",
//                             role: "barista"
//                         })
//                     })

//             }).catch(err => {

//                 dispatch({
//                     type: "GET_EMPLOYEE",
//                     employeeInfo: [],
//                     status: "failure",
//                     role: "barista"
//                 })
//             })
//     }
// }
