const baristaReducer = (state ="", action) => {
    switch (action.type) {
        case "GET_BARISTA_ALL":
            return {...action.barista};
        case "GET_CHECKOUT_ORDER":
            return {...action.barista};
        case "START_ORDER":
            return {...action.barista};
        case "UPDATE_ORDER_ITEMS":
            return {...action.barista};
        case "UPDATE_BARISTA_ORDER_ITEMS_QUANTITY":
            return {...action.barista};    
        default:
            return state;
    }
}
export default baristaReducer