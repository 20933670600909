import axios from "axios";

export const getKitchenOrders = (data) => {
    console.log('Dispatching getKitchenOrders....', data)
    return (dispatch, getState) => {
        
        dispatch({
            type: "GET_KITCHEN_ALL",
            kitchen: {data: data}
        })
    }
}

export const getReadyOrders = (data) => {
    console.log('Dispatching getReadyOrders....', data)
    return (dispatch, getState) => {
        
        dispatch({
            type: "GET_COMPLETED_ORDERS",
            completedOrders: {data: data}
        })
    }
}

export const getOrderOnCheckout = (order_id, branchId, token, kitchenOrder) => {
    console.log("dispatching getOrderOnCheckout...", order_id);
    return (dispatch, getState) => {
        axios.get(`${process.env.REACT_APP_API_URL}api/v1/kitchen/get_order_by_order_id/${order_id}?branch=${branchId}`, {
            headers: { token: token, role: "kitchen" }
        })
            .then(kitchen => {
                
                var kitchenNewOrder = {...kitchenOrder}
                if (kitchen.data.msg === "Token error" || kitchen.data.status_code === 450) {
                    dispatch({
                        type: "GET_EMPLOYEE",
                        employeeInfo: [],
                        status: "failure",
                        role: "kitchen"
                    })
                } else if(kitchen.data.msg == "failure"){
                    // api failed to fetch data
                    console.log('Api failed to fetch order via order id')
                }
                else {
                    if (!kitchenNewOrder.data) {
                        kitchenNewOrder.data = {};
                    }
                    if (!Array.isArray(kitchenNewOrder.data.data)) {
                        kitchenNewOrder.data.data = [];
                    }
                    kitchenNewOrder.data.data.push(kitchen.data.data[0])
                    kitchenNewOrder.data.total_orders = kitchenNewOrder.data.total_orders + 1;
                    dispatch({
                        type: "GET_CHECKOUT_ORDER",
                        kitchen : kitchenNewOrder
                    })
                  
                }

            }).catch(err => {
                console.log('getOrderOnCheckout error', err)
                dispatch({
                    type: "GET_EMPLOYEE",
                    employeeInfo: [],
                    status: "failure",
                    role: "kitchen"
                })
            })
    }
}

export const startKitchenOrders = (order_id, completed_time, start_time, kitchenOrder) => {
    return (dispatch, getState) => {
        console.log('Dispatching startKitchenOrders....', (order_id, completed_time, start_time, kitchenOrder))
        // var kitchen = kitchenOrder.data.data.map( (item, index)=>{
        //     if(order_id == item.order_id){
        //         item.completed_time = completed_time;
        //         item.start_time = start_time
        //     }
        // })
        var kitchenNewOrder = {...kitchenOrder}
        if(Object.keys(kitchenOrder).length === 0){
            const kitchen = JSON.parse(localStorage.getItem('state'));
            kitchenNewOrder = {...kitchen.kitchen}
            window.location.reload();
        }
        for (var i = 0; i< kitchenNewOrder.data.data.length; i++){
            if(kitchenNewOrder.data.data[i].order_id == order_id){
                kitchenNewOrder.data.data[i].completed_time = completed_time;
                kitchenNewOrder.data.data[i].start_time = start_time;
            }
        }

        
        dispatch({
            type: "START_ORDER",
            kitchen: kitchenNewOrder
        })
        

    }
}


export const UpdateOrderItems = (order_id, data, kitchenOrder, status) => {
    console.log('Dispatching UpdateOrderItems....', (order_id, data, kitchenOrder))
    return (dispatch, getState) => {
        var kitchenNewOrder = {...kitchenOrder}
        if(Object.keys(kitchenOrder).length === 0){
            const kitchen = JSON.parse(localStorage.getItem('state'));
            kitchenNewOrder = {...kitchen.kitchen}
            window.location.reload();
        }
        for (var i = 0; i< kitchenNewOrder.data.data.length; i++){
            if(kitchenNewOrder.data.data[i].order_id == order_id){
                if(status == 201){
                    kitchenNewOrder.data.data[i].status = "Completed";
                    kitchenNewOrder.data.total_orders = kitchenNewOrder.data.total_orders - 1;
                    for(var j=0; j<kitchenNewOrder.data.data[i].kitchen.length; j++){
                        
                        if(kitchenNewOrder.data.data[i].kitchen[j].order_items_id == data.order_items_id){
                            kitchenNewOrder.data.data[i].kitchen[j].status = data.status;
                            kitchenNewOrder.data.data[i].kitchen[j].completed_time = data.completed_time;
                            kitchenNewOrder.data.data[i].kitchen[j].prepared_by = data.prepared_by;
                            kitchenNewOrder.data.data[i].kitchen[j].quantity_completed = data.quantity_completed;
                        }
                    }
                }else if(status == 202){
                    kitchenNewOrder.data.data.splice(i,1);
                    kitchenNewOrder.data.total_orders = kitchenNewOrder.data.total_orders - 1
                }else{
                    for(var j=0; j<kitchenNewOrder.data.data[i].kitchen.length; j++){
                        
                        if(kitchenNewOrder.data.data[i].kitchen[j].order_items_id == data.order_items_id){
                            kitchenNewOrder.data.data[i].kitchen[j].status = data.status;
                            kitchenNewOrder.data.data[i].kitchen[j].completed_time = data.completed_time;
                            kitchenNewOrder.data.data[i].kitchen[j].prepared_by = data.prepared_by;
                            kitchenNewOrder.data.data[i].kitchen[j].quantity_completed = data.quantity_completed;
                        }
                    }
                }
                
            }
        }

        
        dispatch({
            type: "UPDATE_ORDER_ITEMS",
            kitchen: kitchenNewOrder
        })

    }
}


// export const UpdateOrderItemsQuantity = (page, branchId, order_items_id, quantityDone, token, role) => {
//     return (dispatch, getState) => {
//         console.log('Dispatching UpdateOrderItemsQuantity....')
//         axios.post(`${process.env.REACT_APP_API_URL}api/v1/kitchen/update_order_items`, {
//             "order_items_id": order_items_id,
//             "status": "Preparing",
//             "completed_time": null,
//             "prepared_by": null,
//             "quantity_completed": quantityDone
//         }, { headers: { token: token, role: "kitchen" } })
//             .then(kitchen => {
                

//             }).catch(err => {
//                 dispatch({
//                     type: "GET_EMPLOYEE",
//                     employeeInfo: [],
//                     status: "failure",
//                     role: "kitchen"
//                 })
//             })
//     }
// }

