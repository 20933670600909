import React from 'react';
import { useRef } from "react";
import { HiBadgeCheck, HiPrinter } from "react-icons/hi";
import { useSelector } from 'react-redux';
import def from "../../assets/images/default.jpg";
import { useReactToPrint } from "react-to-print";
import { OrderItemPrintDisabled } from "./OrderItemPrintDisabled"

const RenderSmallDisabled = ({ newId, orderItem, item }) => {
    const AllProducts = useSelector(state => state.product);
    const itemDetails = AllProducts[orderItem.product_id];
    const componentref = useRef();

    const description = () => {
        if (orderItem.add_ons) {
            return Object.keys(orderItem.add_ons).map((key, i) => {
                if (Array.isArray(orderItem.add_ons[key])) {
                    var output = ''
                    for (var j = 0; j < orderItem.add_ons[key].length; j++) {
                        output = output + orderItem.add_ons[key][j]['value'] + ' ,'
                    }
                    // console.log('output', output)
                    return <span key={i}>{key}:{output.substring(0, output.length - 2)} ,</span>
                } else {
                    return <span key={i}>{key}:{orderItem.add_ons[key]} ,</span>
                }

            }


            )
        }

    }

    const printKT = (e) =>{
        e.stopPropagation();
        print()
    } 
      
    const print = useReactToPrint({
        content: () => componentref.current,
    });


    const kitchenReady = () => {
        if (orderItem.food_type === "Veg") {
            if (orderItem.status === null) {
                return (
                    <div>
                        <button className="btn btn-success btn-sm quan mt-1" disabled style={{opacity: ".6"}}>Qty:{orderItem.quantity}</button>
                        <button className="btn btn-success btn-sm ms-2 mt-1" disabled style={{opacity: ".6"}}>Ready?</button>
                        <div
                            className="btn btn-outline-success btn-sm ms-2 quan mt-1" 
                            onClick={printKT}
                            >
                                <HiPrinter/>
                        </div>
                        <div className="visually-hidden-focusable p-1">
                            <OrderItemPrintDisabled
                                componentref={componentref}
                                order={{
                                    orderItem: orderItem,
                                    item: item
                                }}
                            />
                        </div>
                    </div>

                )
            }
            else {
                return <div style={{opacity: ".6"}}>
                    <button className="btn btn-success btn-sm quan mt-1" disabled style={{opacity: ".6"}}>Qty:{orderItem.quantity}</button>
                    <div className="orderItem-done" style={{ color: "#578d74", opacity: ".6" }}><HiBadgeCheck /></div>
                    <div
                    className="btn btn-outline-success btn-sm ms-2 quan mt-1" 
                    onClick={printKT}
                    >
                            <HiPrinter/>
                    </div>
                    <div className="visually-hidden-focusable p-1">
                        <OrderItemPrintDisabled
                            componentref={componentref}
                            order={{
                                orderItem: orderItem,
                                item: item
                            }}
                        />
                    </div>
                </div>
            }
        }
        else {
            if (orderItem.status === null) {
                return (
                    <div>
                        <button className="btn btn-danger btn-sm quan mt-1" disabled style={{opacity: ".6"}}>Qty:{orderItem.quantity}</button>
                        <button className="btn btn-danger btn-sm ms-2 mt-1" disabled style={{opacity: ".6"}}>Ready?</button>
                        <div
                            className="btn btn-outline-success btn-sm ms-2 quan mt-1" 
                            onClick={printKT}
                            >
                                <HiPrinter/>
                        </div>
                        <div className="visually-hidden-focusable p-1">
                            <OrderItemPrintDisabled
                                componentref={componentref}
                                order={{
                                    orderItem: orderItem,
                                    item: item
                                }}
                            />
                        </div>
                    </div>

                )
            }
            else {
                return <div>
                    <button className="btn btn-danger btn-sm quan mt-1" disabled style={{opacity: ".6"}}>Qty:{orderItem.quantity}</button>
                    <div className="orderItem-done" style={{ color: "#cc3342d1", opacity:".6" }}><HiBadgeCheck /></div>
                    <div
                        className="btn btn-outline-success btn-sm ms-2 quan mt-1" 
                        onClick={printKT}
                        >
                            <HiPrinter/>
                    </div>
                    <div className="visually-hidden-focusable p-1">
                        <OrderItemPrintDisabled
                            componentref={componentref}
                            order={{
                                orderItem: orderItem,
                                item: item
                            }}
                        />
                    </div>
                </div>
            }
        }
    }
    return (
        <div key={newId} className='d-flex mt-3' style={{ position: 'relative' }}>
            <div className='w-25 d-flex justify-content-start align-items-start p-1' style={{opacity: ".6"}}><img className="DoneImage" style={{ borderRadius: "10px" }} src={itemDetails ? itemDetails.product_list.card_img : def} alt={newId + "IMAGE"}></img></div>
            <div className='w-75 ready-small-content'>
                <div className="order-card-title " style={{opacity: ".6"}}>{orderItem.product_name}  {`(${orderItem.order_sku})`}</div>
                {orderItem.food_type === "Veg" ? <div className="foodSign vegSign" style={{opacity: ".6"}}><div className='circle-veg'></div></div> : <div style={{opacity: ".6"}} className="foodSign nonvegSign"><div className='circle-nonveg'></div></div>}
                <div className="order-card-text " style={{opacity: ".6"}}>{description()}</div>
                {kitchenReady()}
                
            </div>
        </div>
    )
};

export default RenderSmallDisabled;