import React, { useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { FaCheckCircle } from "react-icons/fa";
import food from "../../assets/images/baristaIcon.png";
import LoaderLarge from "../../loader";
import "../../css/active.css";
import { IoRefreshCircle } from "react-icons/io5";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getKitchenOrders, getOrderOnCheckout, startKitchenOrders, UpdateOrderItems } from "../../store/actions/kitchenAction";
import BigCard from "./BigCard";
import { getProducts } from "../../store/actions/productAction";
import Footer from "./Footer";
import StickyNote from "../StickyNote";
import { setEmployeeToken } from "../../store/actions/authenticationAction";
import { setPage } from "../../store/actions/paginationAction";
import { toast } from "react-toastify";
import notification from "../../assets/sound/notified.wav";
import delayNotification from "../../assets/sound/film-countdown-88717.mp3";
import { SocketContext } from '../../context/socket.context';


const ActiveKitchen = () => {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch();
  const emp_info = useSelector((state) => state.emp_info);
  const kitchenOrder = useSelector((state) => state.kitchen);
  const page = useSelector((state) => state.page);

  const audioPlayer = useRef(null);
  function playAudio() {
    try{
      audioPlayer.current.play();
    }catch (e){
      console.log('audio player issue', e);
    }
  }

  const delayedPlayer = useRef(null);
  function playDelayedAudio() {
    try{
      // delayedPlayer.current.loop = true;
      delayedPlayer.current.play();
    }catch (e){
      console.log('audio player issue', e);
    }
    
  }

  const [total, setTotal] = useState(0);
  useEffect(()=>{
      if(kitchenOrder?.data?.status === 'success'){
          setTotal(kitchenOrder.data.total_orders);
      }
      else if(kitchenOrder?.data?.status === 'failure' && kitchenOrder?.data?.data && isNaN(kitchenOrder?.data?.total_orders)){
          let j = 0;
          for(let i = 0; i < kitchenOrder?.data?.data.length; i++){
              if(kitchenOrder?.data?.data[i].status === "Preparing"){
                  j++;
              }
          }
          setTotal(j++);
      }
      else{
          setTotal(0)
      }
  },[kitchenOrder])

  const BigCar = () => {
    if (kitchenOrder !== "") {
      if (total > 0) {
        console.log('kitchenOrder here', kitchenOrder.data)
        return kitchenOrder.data.data.map((item, index) => {
          if(item.kitchen.length == 0 || item.status == "Completed" ){
            return
          }else{
            return <BigCard key={index} role={"kitchen"} item={item} playDelayedAudio={playDelayedAudio} />;
          }
          
        });
      } else {
        return <StickyNote data={"No pending Orders!"} />;
      }
    }
  };

  useEffect(() => {
    dispatch(getProducts(emp_info.employeeinfo.data.token));
  }, [emp_info, page]);


  const checkout_handler = (order_id) => {
    console.log('Order id in handler', order_id)
    dispatch(getOrderOnCheckout(order_id, emp_info.employeeinfo.data.branch_id, emp_info.employeeinfo.data.token, kitchenOrder))
    
    toast.success(`NEW ORDER RECEIVED`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    playAudio();
  }

  useEffect(() => {
    console.log('in useEffect for socket change');

    const handleRecieveKitchenOrders = (data) => {
        console.log('recieve_kitchen_orders', data);
        dispatch(getKitchenOrders(data));
    };

    const handleCheckout = (data) => {
        console.log(data.message, data.order_id);
        checkout_handler(data.order_id);
    };

    const handleStartOrderSuccess = (order) => {
        // console.log('start_order_success', order);
        dispatch(startKitchenOrders(order.order_id, order.data.completed_time, order.data.start_time, kitchenOrder));

        if (order.role === "barista") {
            toast.success(`Barista started order ${order.order_id}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            toast.success(`Timer for Order ${order.order_id} started`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const handleStartOrderFailure = (result) => {
        toast.error(result.msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const handleUpdateOrderItemsSuccess = (result) => {
        // console.log('update_order_items_success', result);
        dispatch(UpdateOrderItems(result.order_id, result.data, kitchenOrder, result.status_code));

        if (result.status_code === 201) {
            toast.success(`Full Order #${result.order_id} Ready`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else if (result.status_code === 202) {
            toast.success(`Kitchen Order #${result.order_id} Ready`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const handleUpdateOrderItemsFailure = (result) => {
        toast.error(result.msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    // Add socket listeners
    socket.on("recieve_kitchen_orders", handleRecieveKitchenOrders);
    socket.on("checkout", handleCheckout);
    socket.on("start_order_success", handleStartOrderSuccess);
    socket.on("start_order_failure", handleStartOrderFailure);
    socket.on("update_order_items_success", handleUpdateOrderItemsSuccess);
    socket.on("update_order_items_failure", handleUpdateOrderItemsFailure);

    return () => {
        socket.off("recieve_kitchen_orders", handleRecieveKitchenOrders);
        socket.off("checkout", handleCheckout);
        socket.off("start_order_success", handleStartOrderSuccess);
        socket.off("start_order_failure", handleStartOrderFailure);
        socket.off("update_order_items_success", handleUpdateOrderItemsSuccess);
        socket.off("update_order_items_failure", handleUpdateOrderItemsFailure);
    };
  }, [socket, dispatch, checkout_handler, kitchenOrder]);

  useEffect(async () => {
    // get all kitchen orders on refresh or starting of the page
    if (emp_info) {
      socket.emit("get_kitchen_orders", {
        // page : page,
        branch : emp_info.employeeinfo.data.branch_id,
        token: emp_info.employeeinfo.data.token,
        role: emp_info.role
      })

    }
    return () => socket.disconnect(); 
  }, []);

  const refresh = () => {
    // window.location.reload(false);
    if (emp_info) {
      socket.emit("get_kitchen_orders", {
        // page : page,
        branch : emp_info.employeeinfo.data.branch_id,
        token: emp_info.employeeinfo.data.token,
        role: emp_info.role
      })

    }
    return;
  };

  if (kitchenOrder.data) {
    console.log('kitchenOrder.data', kitchenOrder)
    return (
      <React.Fragment>
        <nav className="container-fluid">
          <div
            className="row  pt-2 pb-2"
            style={{
              whiteSpace: "nowrap",
              borderRadius: "10px",
              margin: "2px",
              border: "1px solid rgba(0,0,0,.125)",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div className="col-sm-6 col-xs-12 d-flex justify-content-start align-items-center">
              <img
                width="40px"
                src={logo}
                alt="logo"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              />
              <span className="main-role cursive ">Kitchen</span>
            </div>

            <div className="col-sm-6 col-xs-12 ">
              <div
                style={{ height: "100%" }}
                className="d-flex justify-content-end align-items-center nav-con "
              >
                <Link
                  to="/"
                  className="navbar-link navContent active-button m-0"
                >
                  {<img className="nav-image-logo" src={food}></img>}
                  <span>Active</span>
                </Link>
                <Link
                  to="/kitchen/ready"
                  className="navbar-link navContent ready-button"
                >
                  {" "}
                  <FaCheckCircle />
                  <span>Ready</span>
                </Link>
                <div
                  className="navbar-link navContent ready-button me-2"
                  onClick={() => {
                    dispatch(setEmployeeToken("kitchen"))
                    window.location.href = '/';
                  }}
                >
                  <span>SignOut</span>
                </div>
                <div className="ready-button" onClick={() => refresh()}>
                  <IoRefreshCircle
                    className="refreshButton"
                    style={{ color: "#5c3706", fontWeight: "900" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* navbar ends */}
        <div className="container-fluid">
          <audio ref={audioPlayer} src={notification} />
          <audio ref={delayedPlayer} src={delayNotification} />
          <div className="row">{BigCar()}</div>
        </div>
        <Footer />
      </React.Fragment>
    );
  } else {
    return <LoaderLarge />;
  }
};

export default ActiveKitchen;
