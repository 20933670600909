import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import logo from "../../assets/images/logo.png"
import {FaUser} from "react-icons/fa"
import { IoRefreshCircle } from "react-icons/io5";
import { useSelector, useDispatch } from 'react-redux';
import { getDeliveredOrders } from '../../store/actions/serverAction';
import LoaderLarge from '../../loader';
import { getProducts } from '../../store/actions/productAction';
import { FaCheckCircle } from "react-icons/fa"
import DeliveredFooter from './DeliveredFooter';
import { setEmployeeToken } from '../../store/actions/authenticationAction';
import StickyNote from '../StickyNote';
import RenderSmallDelivered from './RenderSmallDelivered';
import { SocketContext } from '../../context/socket.context';

const Delivered = (props) => {
    const socket = useContext(SocketContext);

    const dispatch = useDispatch();
    const emp_info = useSelector(state => state.emp_info);
    const AllProducts = useSelector(state => state.product);
    const role = useSelector(state => state.emp_info.role);
    const page = useSelector(state => state.server)
    const completed = useSelector(state => state.delivered)

    // useEffect(() => {
    //     dispatch(getProducts(emp_info.employeeinfo.data.token))
    //     if (emp_info) {
    //         dispatch(getServerDelivered(1, emp_info.employeeinfo.data.branch_id,emp_info.employeeinfo.data.token,emp_info.role))
    //     }

    // }, [])
    
    useEffect(async () => {
        // get all kitchen orders on refresh or starting of the page
        // dispatch(getProducts(emp_info.employeeinfo.data.token))
        if (emp_info) {
            console.log('Emitting get_delivered_orders...')
            socket.emit("get_delivered_orders", {
                // page : page,
                branch: emp_info.employeeinfo.data.branch_id,
                token: emp_info.employeeinfo.data.token,
                role: emp_info.role
            })
        }
        return () => socket.disconnect();
    }, []);

    // Socket event handler
    useEffect(()=>{
        
        socket.on("recieve_delivered_orders", (data)=>{
            console.log('recieve_delivered_orders',data)
            dispatch(
                getDeliveredOrders(
                    data
                )
            );
            
        })
        return () => {
            socket.removeAllListeners();
        }
    }, [socket])


    const getTime = (dateC) => {
        const date = dateC.split("T")[0];
        const time = (dateC.split("T")[1]).split("Z")[0];
        return (`${date}, ${(time).slice(0, -4)}`);
    }
    const refresh = () => {
        // window.location.reload(false);
        if (emp_info) {
            console.log('Emitting get_delivered_orders...')
            socket.emit("get_delivered_orders", {
                // page : page,
                branch: emp_info.employeeinfo.data.branch_id,
                token: emp_info.employeeinfo.data.token,
                role: emp_info.role
            })
        }
        return
    }


    const renderSmall = (item) => {
        if (item) {

            return item.kitchen.map((orderItem, orderIndex) => {
                return<RenderSmallDelivered key={orderIndex} orderItem={orderItem} />

            });
        }
        else {
            return <></>
        }

    }
    const renderSmallBarista = (item) => {
        if (item) {
            return item.barista.map((orderItem, orderIndex) => {
                return<RenderSmallDelivered key={orderIndex} orderItem={orderItem} />
            });

        }
        else {
            return <></>
        }

    }
    const BigCard = () => {
        if (Object.keys(completed).length !== 0 ) {
            if(completed.data.status!=="failure"){
            return completed.data.data.map((item, index) => {

                return (
                    <div key={index} className='col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-3'>
                        <div className="card outer-scroll" style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}>
                            <div className="card-body">
                                <div className='row mb-4'>
                                    <div className='col-8 orderNumber'>Order#{item.order_id}<br /><div className="orderTime" ><div className="col-12 orderTime" >Comments: {item.comment}</div>{getTime(item.createdAt)}
                                    <div style={{fontWeight: "bold", color: 'black'}}>{item.customer_name}</div></div>
                                    
                                    </div>
                                    <div className='col-4 d-flex justify-content-end '><div  className="userImage"  ><FaUser /></div>
                                    {item.order_type==="Take Away"?<div className="bookmarkRibbon bookmarkRibbonTA"><span>T</span></div>:<div className="bookmarkRibbon bookmarkRibbonDI"><span>D</span></div>}</div>
                                </div>
                                <div className='innerScroll mt-2' style={{ overflowY: "auto" }}>
                                    {renderSmall(item)}
                                    {renderSmallBarista(item)}
                                </div>

                            </div>
                        </div>

                    </div>
                );

            })}
            else{
                return<StickyNote data={"NO delivered orders to show"}/>
            }
        }
        else {
            return <LoaderLarge />
        }

    }
    return (
        <React.Fragment>
            <nav className="container-fluid">
                <div className='row pt-2 pb-2' style={{ whiteSpace: "nowrap", borderRadius: "10px", margin: "2px", border: "1px solid rgba(0,0,0,.125)", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                    <div className="col-sm-6 col-xs-12 d-flex justify-content-start align-items-center"><img width="40px" src={logo} alt="logo" style={{ marginTop: "10px", marginBottom: "10px" }} />
                        <span className='main-role mr cursive'>Delivered Orders</span>
                    </div>
                    <div className='col-sm-6 col-xs-12 p-0'>
                        <div style={{ height: "100%" }} className="d-flex justify-content-end align-items-center nav-con ">
                            <Link to="/server" className='navbar-link navContent ready-button '><FaCheckCircle /> <span>Completed</span></Link>
                            <Link to="/server/delivered" className='navbar-link navContent active-button '><span>Delivered</span></Link>
                            <div className='navbar-link navContent ready-button me-2' onClick={() => {
                                dispatch(setEmployeeToken("server"))
                                window.location.href = '/'
                                }}><span>SignOut</span></div>
                            <div className='ready-button' onClick={() => refresh()}><IoRefreshCircle className='refreshButton' style={{ color: "#5c3706", fontWeight: "900" }} /></div>
                        </div>
                    </div>
                </div>
            </nav>
            {/* navbar ends */}
            <div className='container-fluid'>
                <div className='row'>
                
                    {BigCard()}
                </div>
            </div>
          <DeliveredFooter /> 
        </React.Fragment>);
}
export default Delivered;