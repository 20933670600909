const ServerPaginationReducer = (state =1, action) => {
    switch (action.type) {
        case "GET_PAGE_COMPLETED":
            return action.page;
        case "SET_PAGE_COMPLETED":
            return action.page;
       case "GET_PAGE_DELIVERED":
            return action.page;
        case "SET_PAGE_DELIVERED":
            return action.page; 
        default:
            return state;
    }
}
export default ServerPaginationReducer;