import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const CompletedFoot = () => {

    const completedOrder = useSelector(state => state.completed);
    

    return (
        <div className='container-fluid  mt-5  mb-0 foot' style={{ backgroundColor: "#d1cbb9" }}>
            <div className='row' style={{ height: "90px" }}>
                <div className='w-100 h-100 d-flex justify-content-end align-items-center'>
                <div className='btn btn-secondary '>Total Orders:{completedOrder.data?completedOrder.data.total_orders:0}</div>
                    
                </div>
            </div>
        </div>
    );
};

export default CompletedFoot;