import React, { useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { FaCheckCircle } from "react-icons/fa";
import "../../css/active.css";
import coffee from "../../assets/images/coffee-cup-min.png";
import { useState, useEffect, useContext } from "react";
import { IoRefreshCircle } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import BigCard from "./BigCard";
import { getProducts } from "../../store/actions/productAction";
import LoaderLarge from "../../loader";
import { setEmployeeToken } from "../../store/actions/authenticationAction";
import StickyNote from "../StickyNote";
import { setPage } from "../../store/actions/paginationAction";
import { getBaristaOrders, getOrderOnCheckout, startBaristaOrders, UpdateOrderItems } from "../../store/actions/baristaAction";
import FooterBarista from "./FooterBarista";
import { toast } from "react-toastify";
import notification from "../../assets/sound/notified.wav";
import delayNotification from "../../assets/sound/film-countdown-88717.mp3";
import { SocketContext } from '../../context/socket.context';

const ActiveBarista = () => {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext)
  const state = useSelector((state) => state);
  const page = useSelector((state) => state.page);
  const emp_info = useSelector((state) => state.emp_info);
  const BaristaOrder = useSelector((state) => state.barista);
  const audioPlayer = useRef(null);

  function playAudio() {
    try{
      audioPlayer.current.play();
    }catch (e){
      console.log('audio player issue', e);
    }
  }

  const delayedPlayer = useRef(null);
  function playDelayedAudio() {
    try{
      // delayedPlayer.current.loop = true;
      delayedPlayer.current.play();
    }catch (e){
      console.log('audio player issue', e);
    }
    
  }
  const [total, setTotal] = useState(0);
  useEffect(()=>{
      if(BaristaOrder?.data?.status === 'success'){
          setTotal(BaristaOrder.data.total_orders);
      }
      else if(BaristaOrder?.data?.status === 'failure' && BaristaOrder?.data?.data && isNaN(BaristaOrder?.data?.total_orders)){
          let j = 0;
          for(let i = 0; i < BaristaOrder?.data?.data.length; i++){
              if(BaristaOrder?.data?.data[i].status === "Preparing"){
                  j++;
              }
          }
          setTotal(j++);
      }
      else{
          setTotal(0)
      }
  },[BaristaOrder])

  const BigCar = () => {
    if (BaristaOrder !== "") {
      if (total > 0) {
        return BaristaOrder?.data?.data?.map((item, index) => {
          if(item.barista.length == 0 || item.status == "Completed"){
            return
          }else{
            return <BigCard key={index} role={"barista"} item={item} playDelayedAudio={playDelayedAudio} />;
          }
          
        });
      } else {
        return <StickyNote data={"No pending Orders!"} />;
      }
    }
  };

  useEffect(() => {
    dispatch(getProducts(emp_info.employeeinfo.data.token));
  }, [emp_info, page]);

  

  const checkout_handler = (order_id) => {
    console.log('Order id in handler', order_id)
    dispatch(getOrderOnCheckout(order_id, emp_info.employeeinfo.data.branch_id, emp_info.employeeinfo.data.token, BaristaOrder))
    
    toast.success(`NEW ORDER RECEIVED`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
    playAudio();
  }
  
  useEffect(() => {
    console.log('in useEffect for socket change');
  
    const handleReceiveBaristaOrders = (data) => {
      // console.log('receive_barista_orders', data);
      dispatch(getBaristaOrders(data));
    };
  
    const handleCheckout = (data) => {
      if (data && data.message && data.order_id) {
        // console.log("checkout socket for barista",data.message, data.order_id);
        checkout_handler(data.order_id);
      }
    };
  
    const handleStartOrderSuccess = (order) => {
      if (order && order.order_id && order.data) {
        // console.log('start_order_success', order);
        dispatch(
          startBaristaOrders(
            order.order_id,
            order.data.completed_time,
            order.data.start_time,
            BaristaOrder
          )
        );
  
        const roleMessage = order.role === 'kitchen'
          ? `Kitchen started order ${order.order_id}`
          : `Timer for Order ${order.order_id} started`;
  
        toast.success(roleMessage, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    };
  
    const handleStartOrderFailure = (result) => {
      if (result && result.msg) {
        toast.error(result.msg, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    };
  
    const handleUpdateOrderItemsSuccess = (result) => {
      if (result && result.order_id && result.data) {
        // console.log('update_order_items_success', result);
        dispatch(
          UpdateOrderItems(result.order_id, result.data, BaristaOrder, result.status_code)
        );
  
        const successMessage = result.status_code === 201
          ? `Full Order # ${result.order_id} Ready`
          : `Barista Order # ${result.order_id} Ready`;
  
        toast.success(successMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    };
  
    const handleUpdateOrderItemsFailure = (result) => {
      if (result && result.msg) {
        toast.error(result.msg, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    };
    socket.on('recieve_barista_orders', handleReceiveBaristaOrders);
    socket.on('checkout', handleCheckout);
    socket.on('start_order_success', handleStartOrderSuccess);
    socket.on('start_order_failure', handleStartOrderFailure);
    socket.on('update_order_items_success', handleUpdateOrderItemsSuccess);
    socket.on('update_order_items_failure', handleUpdateOrderItemsFailure);
  
    return () => {
      socket.off('recieve_barista_orders', handleReceiveBaristaOrders);
      socket.off('checkout', handleCheckout);
      socket.off('start_order_success', handleStartOrderSuccess);
      socket.off('start_order_failure', handleStartOrderFailure);
      socket.off('update_order_items_success', handleUpdateOrderItemsSuccess);
      socket.off('update_order_items_failure', handleUpdateOrderItemsFailure);
    };
  }, [socket, dispatch, checkout_handler, BaristaOrder]);
  
  

  useEffect(async () => {
    // get all barista orders on refresh or starting of the page
    if (emp_info) {
      socket.emit("get_barista_orders", {
        // page : page,
        branch : emp_info.employeeinfo.data.branch_id,
        token: emp_info.employeeinfo.data.token,
        role: emp_info.role
      })

    }
    return () => socket.disconnect(); 
  }, []);


  const refresh = () => {
    // window.location.reload(false);
    if (emp_info) {
      socket.emit("get_kitchen_orders", {
        // page : page,
        branch : emp_info.employeeinfo.data.branch_id,
        token: emp_info.employeeinfo.data.token,
        role: emp_info.role
      })

    }
    return;
  };

  if (BaristaOrder.data) {
    return (
      <React.Fragment>
        <nav className="container-fluid">
          {/* <audio ref={audioPlayer} src={notification} /> */}
          <div
            className="row pt-2 pb-2"
            style={{
              whiteSpace: "nowrap",
              borderRadius: "10px",
              margin: "2px",
              border: "1px solid rgba(0,0,0,.125)",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <div className="col-sm-6 col-xs-12 d-flex justify-content-start align-items-center">
              <img
                width="40px"
                src={logo}
                alt="logo"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              />
              <span className="main-role cursive ">Barista</span>
            </div>

            <div className="col-sm-6 col-xs-12">
              <div
                style={{ height: "100%" }}
                className="d-flex justify-content-end align-items-center nav-con "
              >
                <Link
                  to="/barista"
                  className="navbar-link navContent active-button m-0"
                >
                  {<img className="nav-image-logo" src={coffee}></img>}
                  <span>Active</span>
                </Link>
                <Link
                  to="/barista/ready"
                  className="navbar-link navContent ready-button"
                >
                  {" "}
                  <FaCheckCircle />
                  <span>Ready</span>
                </Link>
                <div
                  className="navbar-link navContent ready-button me-2"
                  onClick={() => {
                    dispatch(setEmployeeToken("barista"))
                    window.location.href = '/';
                  }}
                >
                  <span>SignOut</span>
                </div>
                <div className="ready-button" onClick={() => refresh()}>
                  <IoRefreshCircle
                    className="refreshButton"
                    style={{ color: "#5c3706", fontWeight: "900" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* navbar ends */}
        <div className="container-fluid">
          <audio ref={audioPlayer} src={notification} />
          <audio ref={delayedPlayer} src={delayNotification} />
          <div className="row">{BigCar()}</div>
        </div>
        <FooterBarista />
      </React.Fragment>
    );
  } else {
    return <LoaderLarge />;
  }
};

export default ActiveBarista;
