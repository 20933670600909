import {io} from 'socket.io-client'
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';



// export const socket = io(process.env.REACT_APP_API_URL + 'kitchen')
export const SocketContext = React.createContext();

// export const SocketContext = () => {
//     // const dispatch = useDispatch();
//     // const ReadyOrder = useSelector(state => state.readyOrders);
//     // const page = useSelector(state => state.readyPage)
//     const emp_info = useSelector(state => state.emp_info)
    
//     if(emp_info){
//         console.log('emp_info', emp_info)
//         if(emp_info.role == 'kitchen'){
//             console.log('emp_info.role', emp_info.role)
//             const socket = io(process.env.REACT_APP_API_URL + 'kitchen')
//             return socket
//         }
//         else if(emp_info.role == 'barista'){
//             console.log('emp_info.role', emp_info.role)
//             const socket = io(process.env.REACT_APP_API_URL + 'barista')
//             return socket
    
//         }else if(emp_info.role == 'server'){
//             console.log('emp_info.role', emp_info.role)
//             const socket = io(process.env.REACT_APP_API_URL + 'server')
//             return socket
//         }
//         else{
//             console.log('empty context 1')
//             return
//         }
//     }else{
//         console.log('empty context 2')
//         return
//     }
    

    
// };