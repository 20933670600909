import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import RenderSmallCompleted from "./RenderSmallCompleted";
import { FaUser } from "react-icons/fa";
import {
  DeliveringAllOrderItem,
  DeliveringOrder,
  DeliveringOrderItem,
  sendMessage,
  sendServerMessage,
} from "../../store/actions/serverAction";
import { SocketContext } from "../../context/socket.context";


const CompletedBigcard = ({ item, index }) => {
  const socket = useContext(SocketContext)
  const dispatch = useDispatch();
  const emp_info = useSelector((state) => state.emp_info);
  const completed = useSelector((state) => state.completed);

  const TimeOnCard = new Date(item.createdAt).toLocaleString();

  // useEffect(() => {
  //   let x = 0;
  //   let y = 0;

  //   for (var i = 0; i < item.barista.length; i++) {
  //     if (item.barista[i].delivery_status === true) {
  //       x = x + 1;
  //     }
  //   }
  //   for (var i = 0; i < item.kitchen.length; i++) {
  //     if (item.kitchen[i].delivery_status === true) {
  //       y = y + 1;
  //     }
  //   }
  //   if (x + y === item.barista.length + item.kitchen.length) {
  //     ClickFunc(item.order_id);
  //   }
  // }, [completed]);

  const getTime = (dateC) => {
    const date = dateC.split("T")[0];
    const time = dateC.split("T")[1].split("Z")[0];
    return `${date}, ${time.slice(0, -4)}`;
  };

  const renderSmall = (item) => {
    if (item) {
      var order_id = item.order_id
      return item.kitchen.map((orderItem, orderIndex) => {
        return <RenderSmallCompleted key={orderIndex} orderItem={orderItem} order_id={order_id} />;
      });
    } else {
      return <></>;
    }
  };
  const renderSmallBarista = (item) => {
    if (item) {
      var order_id = item.order_id
      return item.barista.map((orderItem, orderIndex) => {
        return <RenderSmallCompleted key={orderIndex} orderItem={orderItem}  order_id={order_id}  />;
      });
    } else {
      return <></>;
    }
  };
  // const ClickFunc = (orderId) => {
  //   dispatch(
  //     DeliveringOrder(
  //       orderId,
  //       emp_info.employeeinfo.data.branch_id,
  //       emp_info.employeeinfo.data.token,
  //       emp_info.role
  //     )
  //   );

  //   toast.info(`Order Number ${orderId} Delivered`, {
  //     position: "top-right",
  //     autoClose: 3000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "colored",
  //   });
  // };
  async function prepareAllOrders() {
    // toast.info(`Order Number ${item.order_id} Delivered`, {
    //   position: "top-right",
    //   autoClose: 3000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "colored",
    // });
    // dispatch(
    //   DeliveringAllOrderItem(
    //     item.order_id,
    //     emp_info.employeeinfo.data.branch_id,
    //     emp_info.employeeinfo.data.token,
    //     emp_info.role
    //   )
    // );
    // UpdateAllOrderItems
    if(item.status !== "Completed"){
      return
    }
    socket.emit("update_all_order_items", {
      branch: emp_info.employeeinfo.data.branch_id,
      order_id: item.order_id,
      data: {
        "order_id": item.order_id,
        "delivery_status": true
      }
    })
  }
  const sendMessageClick = () => {
    dispatch(
      sendServerMessage(
        item.customer_no,
        item.order_id,
        emp_info.employeeinfo.data.branch_id,
        emp_info.employeeinfo.data.token
      )
    );
  };
  return (
    <div key={index} className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-3">
      <div
        className="card outer-scroll"
        style={{ width: "100%", borderRadius: "10px", overflow: "hidden" }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-8 orderNumber">
              Order#{item.order_id}
              <br />
              <div className="orderTime">
                <div className="col-12 orderTime">Comments: {item.comment}</div>
                {TimeOnCard}
                <div style={{fontWeight: "bold", color: 'black'}}>
                  {item.customer_name} {item.customer_no}{" "}
                </div>
              </div>
              <div
                className="btn btn-primary btn-sm"
                onClick={prepareAllOrders} style={item.status !== "Completed" ? {opacity: '0.6'} : {opacity: '1'}}
              >
                Full Order Delivered
              </div>
            </div>
            <div className="col-4 d-flex justify-content-end ">
              <div className="userImage">
                <FaUser />
              </div>
              {item.order_type === "Take Away" ? (
                <div className="bookmarkRibbon bookmarkRibbonTA">
                  <span>T</span>
                </div>
              ) : (
                <div className="bookmarkRibbon bookmarkRibbonDI">
                  <span>D</span>
                </div>
              )}
              {item.msg_status === null ? (
                <div
                  className={`btn btn-sm  Msg-button`}
                  onClick={sendMessageClick}
                >
                  Send Message
                </div>
              ) : (
                <div
                  className={`btn btn-sm  Msg-button Msg-button-disabled`}
                  disabled
                >
                  Message Sent
                </div>
              )}
            </div>
          </div>
          <div className="innerScroll mt-2" style={{ overflowY: "auto" }}>
            {renderSmall(item)}
            {renderSmallBarista(item)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedBigcard;
